// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAaG95YvIEYdPudRJ49LkivfoJS2tlEAmk',
  authDomain: 'snowplow-01.firebaseapp.com',
  projectId: 'snowplow-01',
  storageBucket: 'snowplow-01.appspot.com',
  messagingSenderId: '655244531315',
  appId: '1:655244531315:web:5baa74ba1ce27439c8bc06',
  measurementId: 'G-8L98REQ2TR',
  databaseURL: 'https://snowplow-01.firebaseio.com',
}

// eslint-disable-next-line import/prefer-default-export
export { firebaseConfig }
