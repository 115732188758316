<template>
  <div>
    <h4>You will need to connect your Snowflake account if you want to do any type of Snowflake maintenance or write operations.</h4>
    <h5 class="mt-5">
      How would you like to connect your Snowflake account ({{ account.sfid }})?
    </h5>
    <v-expansion-panels
      v-model="activeExpansionPanel"
      class="mt-5"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span class="info--text">
            <v-icon
              left
              color="info"
            >
              {{ icons.mdiSnowflake }}
            </v-icon>
            <strong>Authenticate with Snowflake using Snowflake OAuth</strong>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- Snowflake Oath Login -->
          <v-card-text>
            <v-btn
              v-if="oauthVerificationCheck !== 'Success'"
              :disabled="!account || !account.snowflakeOauth || !account.snowflakeOauth.enabled"
              :loading="snowflakeOathLoading"
              block
              :color="oauthVerificationCheck === 'Failed' ? 'error' : 'info'"
              type="submit"
              class="mt-6"
              @click="oAuthConnectionTest = true"
            >
              {{ oauthVerificationCheck === 'Failed' ? 'Authentication Failed' : 'Authenticate with Snowflake' }}
            </v-btn>
            <v-btn
              v-if="oauthVerificationCheck === 'Success'"
              block
              color="success"
              class="mt-6"
            >
              Authentication was Successful
            </v-btn>
            <v-row
              justify="center"
              class="mt-5"
            >
              <span v-if="!account || !account.snowflakeOauth || !account.snowflakeOauth.enabled">Snowflake OAuth Has Not Been Setup Yet</span>
            </v-row>
            <v-alert
              v-if="snowflakeOathError"
              class="mt-3"
              type="error"
              text
            >
              {{ snowflakeOathError }}
            </v-alert>
            <v-row
              justify="space-around"
              class="mt-5"
            >
              <v-btn
                text
                small
                @click="setupSnowflakeOauth"
              >
                Setup Snowflake OAuth
              </v-btn>
            </v-row>
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span class="primary--text">
            <v-icon
              left
              color="primary"
            >
              {{ icons.mdiKeyChainVariant }}
            </v-icon>
            <strong>Use Snowflake Credentials (Username/Password)</strong>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- username/password login form -->
          <v-card-text>
            <v-text-field
              v-model="snowflakeUsername"
              outlined
              label="Snowflake Username"
              required
              hide-details="auto"
              class="mb-6"
              hint="Note: If your username contains non-alphanumeric characters it must be wrapped in double quotes and is case sensitive"
              persistent-hint
              @focus="resetLoginPasswordFields"
            ></v-text-field>

            <v-text-field
              v-model="snowflakePassword"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Snowflake Password"
              :error-messages="errorMessages.password"
              placeholder="Password"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
              required
              hide-details="auto"
              class="mb-2"
              @click:append="isPasswordVisible = !isPasswordVisible"
              @focus="resetLoginPasswordFields"
            ></v-text-field>

            <v-btn
              v-if="passwordVerificationCheck !== 'Success'"
              :loading="loadingPasswordCheck"
              block
              color="primary"
              type="submit"
              class="mt-6"
              @click="verifyPasswordCredentials"
            >
              Verify Credentials
            </v-btn>
            <v-btn
              v-if="passwordVerificationCheck === 'Success'"
              block
              color="success"
              type="submit"
              class="mt-6"
            >
              Credentials Verified
            </v-btn>
          </v-card-text>
          <v-alert
            v-if="loginError"
            type="error"
          >
            {{ loginError }}
          </v-alert>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <template>
      <v-dialog
        v-model="oAuthConnectionTest"
        persistent
        width="500"
      >
        <v-card>
          <v-card-title class="headline">
            Snowflake OAuth Verification
          </v-card-title>
          <v-card-text>Click "VERIFY" button and a new window should open. You should be directed to your Snowflake instance for Authentication.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"

              @click="snowflakeOath"
            >
              Verify
            </v-btn>
            <v-btn
              color="info"
              @click="oAuthConnectionTest = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
import { encryptData } from '@/functions'
import { snowflakeQuery } from '@/snowflake'
import { required } from '@core/utils/validation'
// eslint-disable-next-line object-curly-newline
import { mdiEyeOffOutline, mdiEyeOutline, mdiKeyChainVariant, mdiSnowflake } from '@mdi/js'
import serverConfig from '@serverConfig'
import { onBeforeUnmount, onMounted, ref, watchEffect } from '@vue/composition-api'

export default {
  props: {
    account: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const snowflakeVerifiedUserData = ref(null)
    const loadingPasswordCheck = ref(false)
    const loadingOauthCheck = ref(false)
    const verificationMethod = ref(null)
    const oAuthConnectionTest = ref(false)
    const activeExpansionPanel = ref(null)
    const snowflakeOathLoading = ref(null)
    const snowflakeOathError = ref(null)
    const snowflakeAccount = props.account.sfid
    const snowflakeUsername = ref(null)
    const snowflakePassword = ref(null)
    const encryptedSnowflakePassword = ref(null)
    const loginError = ref(null)
    const isPasswordVisible = ref(false)
    const errorMessages = ref([])
    const passwordVerificationCheck = ref(null)
    const oauthVerificationCheck = ref(null)

    const testSnowflakeQuery = async () => {
      try {
        // Test Credentials by Getting User Data From Snowflake
        const getUserResponse = await snowflakeQuery('select current_user;')
        console.log('user response', getUserResponse)
        snowflakeUsername.value = getUserResponse.rows[0].CURRENT_USER
        const getUserDetailsFromSnowflake = await snowflakeQuery(`describe user "${snowflakeUsername.value}"`)
        const userData = getUserDetailsFromSnowflake.rows
        console.log('userData', userData)

        // Store Important User Data
        snowflakeVerifiedUserData.value = {
          username: userData.filter(f => f.property === 'LOGIN_NAME')[0].value,
          role: userData.filter(f => f.property === 'DEFAULT_WAREHOUSE')[0].value,
          warehouse: userData.filter(f => f.property === 'DEFAULT_ROLE')[0].value,
        }

        return 'Success'
      } catch (err) {
        const errorMessage = err
        throw errorMessage
      }
    }

    const oauthSuccessful = async () => {
      if (localStorage.getItem('snowflakeAccessToken')) {
        // Set LocalStorage Object to run Test Query
        const userObject = props.user
        userObject.snowflakeCredentials = {
          username: localStorage.getItem('snowflakeUsername'),
          oauthVerified: true,
        }
        localStorage.setItem('user', JSON.stringify(userObject))
        try {
          const testResponse = await testSnowflakeQuery()
          if (testResponse === 'Success') {
            loadingOauthCheck.value = false
            passwordVerificationCheck.value = 'Success'
            emit('setSnowflakeUserDetails', snowflakeVerifiedUserData.value)
            emit('setSnowflakeAccountVerified', 'oauth')
          } else {
            emit('setSnowflakeAccountVerified', null)
            snowflakeOathError.value = 'Snowflake Refused the Oauth User'
          }
        } catch (err) {
          emit('setSnowflakeAccountVerified', null)
          snowflakeOathError.value = 'Snowflake Refused the Oauth User'
          loadingOauthCheck.value = false
        }
      } else {
        snowflakeOathError.value = 'Was not able to get Snowflake Token. Try Authenticating Again.'
        oauthVerificationCheck.value = null
        emit('setSnowflakeAccountVerified', null)
        loadingOauthCheck.value = false
      }
    }

    const setupSnowflakeOauth = () => {
      localStorage.setItem('snowflakeAccountId', snowflakeAccount)
      const url = `${serverConfig.baseUrl}/account-setup/oauth-setup`
      window.open(url, '_blank')
    }

    const snowflakeOath = async () => {
      oAuthConnectionTest.value = false
      localStorage.setItem('oauthConnectionTest', true)
      localStorage.setItem('snowflakeAccountId', snowflakeAccount)
      const url = `${serverConfig.baseUrl}/account-setup/oauth-setup-test`
      window.open(url, '_blank')
    }

    const resetLoginPasswordFields = () => {
      passwordVerificationCheck.value = null
      loginError.value = null
      emit('setSnowflakeAccountVerified', null)
    }

    const verifyPasswordCredentials = async () => {
      if (snowflakeUsername.value && snowflakePassword.value) {
        loadingPasswordCheck.value = true
        console.log('Checking Credentials on Snowflake...')

        // Encrypt Password and Set on Local Storage for Testing
        const encryptedPassword = await encryptData(snowflakePassword.value)
        encryptedSnowflakePassword.value = encryptedPassword.data
        const userObject = props.user
        userObject.snowflakeCredentials = {
          username: snowflakeUsername.value,
          password: encryptedSnowflakePassword.value,
        }
        localStorage.setItem('user', JSON.stringify(userObject))
        try {
          const testResponse = await testSnowflakeQuery()
          console.log('testResponse', testResponse)
          if (testResponse === 'Success') {
            loadingPasswordCheck.value = false
            passwordVerificationCheck.value = 'Success'
            snowflakeVerifiedUserData.value.password = encryptedSnowflakePassword.value
            emit('setSnowflakeUserDetails', snowflakeVerifiedUserData.value)
            emit('setSnowflakeAccountVerified', 'password')
          } else {
            emit('setSnowflakeAccountVerified', null)
            loginError.value = 'Login Failed. Please check username and password.'
            loadingPasswordCheck.value = false
          }
        } catch (err) {
          emit('setSnowflakeAccountVerified', null)
          loginError.value = 'Login Failed. Please check username and password.'
          loadingPasswordCheck.value = false
        }
      }
    }

    const onStorageUpdate = event => {
      if (event.key === 'oauthVerificationCheck') {
        oauthVerificationCheck.value = event.newValue
        if (event.oldValue === null && event.newValue === 'Success') {
          // Recognized Success Oauth - Execute Test Query
          console.log('Oauth was successful: Passing to Snowflake Query...')
          oauthSuccessful()
        }
      }
    }

    // Watch Criteria to Activate "NEXT" Button on Parent
    watchEffect(() => {
      if (oauthVerificationCheck.value === 'Success' || passwordVerificationCheck.value === 'Success') {
        const successType = oauthVerificationCheck.value === 'Success' ? 'oauth' : 'password'
        verificationMethod.value = successType
        emit('setSnowflakeAccountVerified', successType)
      } else {
        emit('setSnowflakeAccountVerified', null)
      }
    })

    onMounted(() => {
      // Watch For Changes From the localStorage (Other Tab Reactions)
      window.addEventListener('storage', onStorageUpdate)
    })

    onBeforeUnmount(() => {
      localStorage.removeItem('oauthConnectionTest')
      localStorage.removeItem('oauthVerificationCheck')
    })

    return {
      oauthSuccessful,
      resetLoginPasswordFields,
      passwordVerificationCheck,
      loadingPasswordCheck,
      verifyPasswordCredentials,
      oauthVerificationCheck,
      oAuthConnectionTest,
      isPasswordVisible,
      snowflakeUsername,
      snowflakePassword,
      loginError,
      snowflakeOathError,
      setupSnowflakeOauth,
      snowflakeOath,
      props,
      snowflakeOathLoading,
      activeExpansionPanel,
      serverConfig,
      required,
      errorMessages,
      icons: {
        mdiSnowflake,
        mdiKeyChainVariant,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>
