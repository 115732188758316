/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
import serverConfig from '@serverConfig'
import { functions, httpsCallable } from '../firebaseApp'

// Logout and Send back to Login Page
// Using window open as using Router or Store causes dependance errors
function logout() {
  const url = `${serverConfig.baseUrl}/logout`
  window.open(url, '_self')
}

// Function for Retrieving a valid Snowflake OAuth ACCESS Token Either from a new Authentication Code or Refresh Code
async function getTokens(account) {
  const snowflakeCode = localStorage.getItem('snowflakeCode')
  const snowflakeRefreshToken = localStorage.getItem('snowflakeRefreshToken')
  const snowflakeAccessToken = localStorage.getItem('snowflakeAccessToken')
  const snowflakeRefreshTokenExpiration = localStorage.getItem('snowflakeRefreshTokenExpiration')
  const snowflakeAccessTokenExpiration = localStorage.getItem('snowflakeAccessTokenExpiration')

  // Remove Snowflake Code if in LocalStorage
  localStorage.removeItem('snowflakeCode')

  // If Local Storage Is Empty Exit
  if (!snowflakeCode && !snowflakeRefreshToken && !snowflakeAccessToken) {
    console.log('ALL TOKENS MISSING! LOGGING OUT!')
    logout()

    return null
  }

  // Check if Access Token is still Valid
  // Also Added One Minute Buffer
  if (Date.now() < snowflakeAccessTokenExpiration - 60000) {
    return snowflakeAccessToken
  }

  // Check if Refresh Token is still Valid - If Not Log User Out
  // Also Added One Minute Buffer
  if (!snowflakeCode && (!snowflakeRefreshTokenExpiration || Date.now() > snowflakeRefreshTokenExpiration - 60000)) {
    console.log('Snowflake Tokens Expired: Logging Out User...')
    localStorage.setItem('logoutMessage', 'Your Snowflake token has expired and logged you out. Please login again.')
    logout()

    return null
  }
  try {
    const payload = {
      snowflakeAccount: localStorage.getItem('snowflakeAccountId'),
    }

    // If Logged In Get Account - If Not Account Will Be Retrieved From Function
    if (account && account.sfid) {
      payload.snowflakeAccount = account.sfid
      payload.accountId = account.id
      payload.client_id = account.snowflakeOauth.clientId
      payload.client_secret = account.snowflakeOauth.clientSecret
    }

    const data = { redirect_uri: serverConfig.snowflakeOauthRedirectURI }
    if (snowflakeCode) {
      data.code = snowflakeCode
      data.grant_type = 'authorization_code'
    } else {
      data.refresh_token = snowflakeRefreshToken
      data.grant_type = 'refresh_token'
    }
    payload.data = { ...data }
    const snowflakeOathCloudFunction = httpsCallable(functions, 'snowflakeOath')
    const response = await snowflakeOathCloudFunction(payload)
    const tokenData = response.data

    // Write Tokens to Local Storage
    localStorage.setItem('snowflakeAccessToken', tokenData.access_token)
    localStorage.setItem('snowflakeAccessTokenExpiration', Date.now() + tokenData.expires_in * 1000)
    if (snowflakeCode) {
      localStorage.setItem('snowflakeUsername', tokenData.username)
      localStorage.setItem('snowflakeScope', tokenData.scope)
      localStorage.setItem('snowflakeRefreshToken', tokenData.refresh_token)
      localStorage.setItem('snowflakeRefreshTokenExpiration', Date.now() + tokenData.refresh_token_expires_in * 1000)
    }

    return tokenData.access_token
  } catch (err) {
    const errorMessages = `Could not refresh tokens from Snowflake: ${err}`
    throw errorMessages
  }
}

async function getAccount(snowflakeAccount) {
  // Cloud Function
  try {
    const getAccountData = httpsCallable(functions, 'getAccount')
    const response = await getAccountData({ snowflakeAccount })

    return response.data
  } catch (err) {
    console.log('Could not get Oauth Account', err)
  }

  return null
}

async function getUser(accountId, snowflakeUsername) {
  // Cloud Function
  const getUserData = httpsCallable(functions, 'getUser')
  const response = await getUserData({ accountId, snowflakeUsername })

  return response.data
}

async function getOathCode(snowflakeAccount) {
  const accountData = await getAccount(snowflakeAccount)
  if (!accountData) return 'No Account'

  // const { clientId } = accountData
  if (
    (accountData && !accountData.snowflakeOauth) ||
    (accountData && accountData.snowflakeOauth && !accountData.snowflakeOauth.enabled)
  ) {
    return 'No Snowflake Oauth'
  }
  const { clientId, autoCreateAccount, defaultRole } = accountData.snowflakeOauth
  localStorage.setItem('accountId', accountData.id)
  localStorage.setItem('accountDefaultRole', defaultRole)
  if (autoCreateAccount) localStorage.setItem('autoCreateAccount', true)
  try {
    // Open Browser for Authentication
    const params = {
      client_id: encodeURIComponent(clientId),
      response_type: 'code',
      redirect_uri: encodeURIComponent(serverConfig.snowflakeOauthRedirectURI),
    }

    // If Using LOCAL OAUTH USE THE FOLLOWING INSTEAD! // USING SF_OAUTH_INTEGRATION_LOCAL
    if (serverConfig.environment === 'local') {
      console.log('USING LOCAL INSTANCE OF SNOWFLAKE OAUTH!')
      // eslint-disable-next-line no-alert
      alert('USING LOCAL INSTANCE OF SNOWFLAKE OAUTH!')
      params.client_id = encodeURIComponent(serverConfig.localOauthClientId)
      params.client_secret = encodeURIComponent(serverConfig.localOauthClientSecret)
    }

    // Snowflake Account: bm60497.us-central1.gcp lendio.us-east-1
    const url = `https://${snowflakeAccount}.snowflakecomputing.com/oauth/authorize?client_id=${params.client_id}&response_type=code&redirect_uri=${params.redirect_uri}`
    window.open(url, '_self')

    return null
  } catch (err) {
    const errorMessage = `There was an error with the Snowflake Oath Authentication: ${err}`
    throw errorMessage
  }
}

export { getAccount, getUser, getOathCode, getTokens }
