// import { writeDoc } from '@/firestore'
import { cloudFunction } from '@/functions'
import { refreshSnowflakeUsersAndRoles } from '@/snowflake/snowflakeAdministration'
import { getSnowflakeConsumption } from '@/snowflake/snowflakeConsumption'
import { getSnowflakeExecutions } from '@/snowflake/snowflakeExecutions'

// Initiates multiple refreshes
async function initiateAllRefreshes(snowflakeBookmarks) {
  const promises = []

  promises.push(refreshSnowflakeUsersAndRoles())
  promises.push(getSnowflakeConsumption({ snowflakeBookmarks, type: 'daily', writeToDB: true }))
  promises.push(getSnowflakeConsumption({ snowflakeBookmarks, type: 'monthly', writeToDB: true }))
  promises.push(getSnowflakeExecutions({ snowflakeBookmarks, type: 'daily', writeToDB: true }))
  promises.push(getSnowflakeExecutions({ snowflakeBookmarks, type: 'monthly', writeToDB: true }))
  promises.push(cloudFunction({ functionName: 'refreshTableFreshness', payload: { timeType: 'Daily' } }))
  promises.push(cloudFunction({ functionName: 'refreshTableFreshness', payload: { timeType: 'Monthly' } }))
  promises.push(cloudFunction({ functionName: 'snowflakeTableStorageRefresh' }))
  promises.push(cloudFunction({ functionName: 'snowflakeTasksRefresh' }))
  const promiseResponse = await Promise.all(promises)

  return promiseResponse
}

// eslint-disable-next-line import/prefer-default-export
export { initiateAllRefreshes }
