<template>
  <div>
    <layout-content-vertical-nav
      :nav-menu-items="navMenuItems"
    >
      <!-- SYSTEM MESSAGES -->
      <div
        v-if="systemMessages.length > 0"
      >
        <v-row>
          <v-col
            v-for="message in systemMessages"
            :key="message.id"
            sm="12"
            class="my-0 py-0"
          >
            <v-alert
              :type="message.type"
              :icon="message.icon"
              :dismissible="message.dismissible"
              :text="message.light"
              :border="message.border"
              :color="message.color"
            >
              <!-- eslint-disable vue/no-v-html -->
              <span v-html="message.message"></span>
              <template
                v-if="message.dismissible"
                v-slot:close="{ toggle }"
              >
                <v-btn
                  small
                  icon
                  dark
                  @click="closeSystemMessage(message, toggle)"
                >
                  <v-icon>{{ icons.mdiCloseCircle }}</v-icon>
                </v-btn>
              </template>
              <!--eslint-enable-->
            </v-alert>
          </v-col>
        </v-row>
      </div>
      <v-alert
        v-if="showDataSyncAlert.length > 0"
        dismissible
        color="primary"
        border="left"
        elevation="2"
        colored-border
      >
        <v-btn
          icon
          loading
        ></v-btn>
        {{ dataSyncAlertText }}
        <v-icon v-if="showDataSyncAlert.includes('users')">
          {{ icons.mdiAccountMultipleOutline }}
        </v-icon>
        <v-icon v-if="showDataSyncAlert.includes('executions')">
          {{ icons.mdiCogClockwise }}
        </v-icon>
        <v-icon v-if="showDataSyncAlert.includes('consumption')">
          {{ icons.mdiCurrencyUsd }}
        </v-icon>
        <v-icon v-if="showDataSyncAlert.includes('schema')">
          {{ icons.mdiFileTableBoxOutline }}
        </v-icon>
      </v-alert>
      <slot></slot>
      <!-- Slot: Navbar -->
      <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
        <div
          class="navbar-content-container"
          :class="{'expanded-search': shallShowFullSearch}"
        >
          <!-- Left Content: Search -->
          <div class="d-flex align-center">
            <v-icon
              v-if="$vuetify.breakpoint.mdAndDown"
              class="me-3"
              @click="toggleVerticalNavMenuActive"
            >
              {{ icons.mdiMenu }}
            </v-icon>
            <app-bar-search
              :shall-show-full-search.sync="shallShowFullSearch"
              :data="appBarSearchData"
              :filter="searchFilterFunc"
              :search-query.sync="appBarSearchQuery"
              @update:shallShowFullSearch="handleShallShowFullSearchUpdate(isVerticalNavMenuActive, toggleVerticalNavMenuActive)"
            ></app-bar-search>
          </div>

          <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
          <div class="d-flex align-center right-row">
            <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher>
            <app-bar-notification></app-bar-notification>
            <app-bar-user-menu></app-bar-user-menu>
          </div>
        </div>
      </template>

      <!-- Slot: Footer -->
      <template #footer>
        <div class="d-flex justify-space-between">
          <span>COPYRIGHT &copy; {{ new Date().getFullYear() }} <a
            href="#"
            class="text-decoration-none"
          >Tessella Technologies</a><span class="d-none d-md-inline">, All rights Reserved</span></span>
        </div>
      </template>

      <template #v-app-content>
        <app-customizer class="d-none d-md-block"></app-customizer>
      </template>
    </layout-content-vertical-nav>
    <div>
    </div>
  </div>
</template>

<script>
/* eslint-disable lines-around-comment */
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
// Search Data
import appBarSearchData from '@/assets/app-bar-search-data'
import store from '@/store'
import navMenuItems from '@/views/navigation-menu/navigationMenu'
import AppBarNotification from '@/views/ui/components/AppBarNotification.vue'
// App Bar Components
import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'
// import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import AppBarUserMenu from '@core/layouts/components/app-bar/AppBarUserMenu.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { getVuetify } from '@core/utils'
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountMultipleOutline,
  mdiCloseCircle,
  mdiCogClockwise,
  mdiCurrencyUsd,
  mdiFileTableBoxOutline,
  mdiHeartOutline,
  mdiLogoutVariant,
  mdiMenu,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'

export default {
  components: {
    LayoutContentVerticalNav,
    AppCustomizer,

    // App Bar Components
    AppBarSearch,

    // AppBarI18n,
    AppBarThemeSwitcher,
    AppBarUserMenu,
    AppBarNotification,
  },
  setup() {
    const $vuetify = getVuetify()

    const closeSystemMessage = message => {
      store.dispatch('removeSystemMessage', message)
    }

    const systemMessages = computed(() => store.state.systemMessages)

    // Control Top Alert For Data Sync Notification
    const showDataSyncAlert = computed(() => {
      const alerts = []
      const loadingStatus = store.state.loadingBanner
      if (loadingStatus.users) alerts.push('users')
      if (loadingStatus.consumption) alerts.push('consumption')
      if (loadingStatus.executions) alerts.push('executions')
      if (loadingStatus.schema) alerts.push('schema')

      return alerts
    })
    const dataSyncAlertText = computed(() => {
      // Add random text variations for fun
      const textVariations = ['Refreshing Data ...']

      return textVariations[0]
    })

    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })
    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }

    return {
      closeSystemMessage,
      systemMessages,
      showDataSyncAlert,
      dataSyncAlertText,
      navMenuItems,
      handleShallShowFullSearchUpdate,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,

      icons: {
        mdiCogClockwise,
        mdiMenu,
        mdiHeartOutline,
        mdiLogoutVariant,
        mdiAccountMultipleOutline,
        mdiCurrencyUsd,
        mdiCloseCircle,
        mdiFileTableBoxOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
