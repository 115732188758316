import { cloudFunction } from '@/functions'
import store from '@/store'

const account = store.state.accountProfile || JSON.parse(localStorage.getItem('accountProfile'))
const timeZone = JSON.parse(localStorage.getItem('timeZone'))

// Snowflake Executions //////////////
// //////////////////// //////////////

// Returns Data Object: // Grouped by 'hourly' or 'daily' or 'monthly'
// { month/year: [YYYYMM/YYYY], rows: [ day: 'YYYY[MMDD/MM]', total[Daily/Monthly]ElapsedTime: NUMBER, total[Daily/Monthly]Executions: NUMBER, { users: KeyValuesOfUsers,  } ]}
async function getSnowflakeExecutions(payload) {
  if (!payload.snowflakeBookmarks) {
    const error = 'Missing snowflakeBookmarks in Payload!'
    throw error
  }
  const bookmarks = payload.snowflakeBookmarks
  const {
    type = 'daily', // 'hourly', 'daily' or 'monthly' aggregation returned // Do not write 'hourly' to DB
    table, // If Desired Table Override
    writeToDB = false, // true or false : Should Results Write to DB
    returnWriteResults = false, // set to true if you want to return the results set AND write to DB
    loadType = 'partial', // 'partial' or 'full' : Partial Executes Faster Against An Already Existing Record
    startDate, // If NULL Function will Utilize Bookmarks - Keep NULL for Standard Refreshes
    endDate = new Date(),
  } = payload

  store.commit('setLoadingBannerState', { key: 'executions', value: true })
  try {
    const functionPayload = {
      functionName: 'snowflakeExecutions',
      payload: {
        account,
        type,
        writeToDB,
        returnWriteResults,
        loadType,
        endDate,
        timeZone: timeZone.name,
        bookmarks,
      },
    }
    if (table) functionPayload.payload.table = table
    if (startDate) functionPayload.payload.startDate = startDate
    const snowflakeExecutionsResponse = await cloudFunction(functionPayload)
    store.commit('setLoadingBannerState', { key: 'executions', value: false })

    return snowflakeExecutionsResponse
  } catch (error) {
    store.commit('setLoadingBannerState', { key: 'executions', value: false })
    console.log('THIS IS THE ERROR RESULT', error)
    console.log('TYPE', typeof error)

    // if (typeof error !== 'string' || (typeof error === 'string' && !error.includes('deadline-exceeded'))) {
    //   store.dispatch('systemMessages', `<strong>${error}</strong>`)
    // }
    throw error
  }
}

// eslint-disable-next-line import/prefer-default-export
export { getSnowflakeExecutions }
