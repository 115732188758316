import Cron from 'cron-converter'

const cronInstance = new Cron()

// Return Cron Converted Object
function cronConversion(cronString) {
  cronInstance.fromString(cronString)
  const schedule = cronInstance.schedule()

  // Schedule Can Be Manipulated as momendJs object
  // schedule.next().format()
  return schedule
}

if (localStorage.getItem('user') === 'undefined') localStorage.removeItem('user')
const user = JSON.parse(localStorage.getItem('user'))
const userTimeZone = user?.timeZone && user?.timeZone === 'UseLocalTime' ? null : user?.timeZone || null

// Gets All Dates Within a Period of Dates
function dateArray(startDate, endDate) {
  startDate.setHours(0, 0, 0, 0)
  const arr = []
  for (let currentDate = startDate; currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
    const date = new Date(currentDate.getTime())
    arr.push(date)
  }

  // arr[arr.length - 1] = new Date(arr[arr.length - 1].setHours(23, 59, 59, 59))

  return arr
}

function timeZoneOffset(date = new Date(), timeZone = userTimeZone) {
  if (timeZone) {
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }))
    const tzDate = new Date(date.toLocaleString('en-US', { timeZone }))

    return (tzDate.getTime() - utcDate.getTime()) / 6e4 / 60
  }

  return (new Date().getTimezoneOffset() / 60) * -1
}

function convertTimezone(date = new Date(), timeZone = userTimeZone) {
  let dateTimeParts = ''
  let timeZoneOffsetCalc = null
  if (timeZone) {
    dateTimeParts = date.toLocaleString('en-US', { timeZone, hour12: false })
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }))
    timeZoneOffsetCalc = (new Date(dateTimeParts).getTime() - utcDate.getTime()) / 6e4 / 60
    dateTimeParts = dateTimeParts.split(',')
  } else {
    dateTimeParts = date.toLocaleString('en-US', { hour12: false }).split(',')
    timeZoneOffsetCalc = (new Date().getTimezoneOffset() / 60) * -1
  }
  let dateParts = dateTimeParts[0].split('/')
  dateTimeParts = dateTimeParts[1].trim()
  dateParts = dateParts.map(m => (m.length === 1 ? `0${m}` : m))
  timeZoneOffsetCalc = timeZoneOffsetCalc.length < 10 ? `0${timeZoneOffsetCalc}` : timeZoneOffsetCalc
  const convertedDate = `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}T${dateTimeParts}.000${
    timeZoneOffsetCalc < 0 ? '-' : '+'
  }${Math.abs(timeZoneOffsetCalc) < 10 ? `0${Math.abs(timeZoneOffsetCalc)}` : Math.abs(timeZoneOffsetCalc)}:00`

  return convertedDate
}

function dateOffset(offsetType, offsetValue, date = new Date(), fullDay = false, fullOffset = false) {
  // Offsets in minutes, hours, days, months, or years
  let d = new Date(date.getTime())
  if (offsetType === 'minutes') d.setMinutes(d.getMinutes() + offsetValue)
  if (offsetType === 'hours') d.setHours(d.getHours() + offsetValue)
  if (offsetType === 'days') d.setDate(d.getDate() + offsetValue)
  if (offsetType === 'months') d.setMonth(d.getMonth() + offsetValue)
  if (offsetType === 'years') d.setFullYear(d.getFullYear() + offsetValue)
  if (fullDay) {
    // Offset First Day To FULL DAY
    d.setHours(0, 0, 0, 0)
  }
  if (fullOffset) {
    // Offsets to the FIRST DAY of the offsetType of months and years
    if (offsetType === 'months') d = new Date(d.getFullYear(), d.getMonth(), 1)
    if (offsetType === 'years') d = new Date(d.getFullYear(), 0, 1)
  }

  return d
}

function createCronFromDate(date, offsetType, offsetValue) {
  // offsetsAreOptional minutes, hours, or days
  let d = new Date(date.getTime())
  if (offsetType) d = dateOffset(offsetType, offsetValue, d)
  const minute = d.getMinutes()
  const hour = d.getHours()
  const day = d.getDate()
  const month = d.getMonth() + 1

  // cron minute, hour, day, month, day_of_week
  return `${minute} ${hour} ${day} ${month} *`
}

// Gets Date Object Back From Blocked Date
function dateFromBlock(blockedDate) {
  const tz = timeZoneOffset()
  let newDate = String(blockedDate)
  if (newDate.length === 12) {
    // Minutes
    newDate = `${newDate.substring(0, 4)}-${newDate.substring(4, 6)}-${newDate.substring(6, 8)}T${newDate.substring(
      8,
      10,
    )}:${newDate.substring(10, 12)}:00.000`
  } else if (newDate.length === 10) {
    // Hour
    newDate = `${newDate.substring(0, 4)}-${newDate.substring(4, 6)}-${newDate.substring(6, 8)}T${newDate.substring(
      8,
      10,
    )}:00:00.000`
  } else if (newDate.length === 8) {
    // Day
    newDate = `${newDate.substring(0, 4)}-${newDate.substring(4, 6)}-${newDate.substring(6, 8)}T00:00:00.000`
  } else if (newDate.length === 6) {
    // Month
    newDate = `${newDate.substring(0, 4)}-${newDate.substring(4, 6)}-01T00:00:00.000`
  } else {
    // Year
    newDate = `${newDate.substring(0, 4)}-01-01T00:00:00.000`
  }
  newDate = `${newDate}${tz < 0 ? '-' : '+'}${Math.abs(tz) < 10 ? `0${Math.abs(tz)}` : Math.abs(tz)}:00`

  newDate = new Date(newDate)

  return newDate
}

// Removes all characters from Date and Time, Specify OPTIONAL "type" of "day", "month", or "year" to truncate characters
// Get Date From Timezone Conversion
// utc = true for UTC conversion

function dateBlock(date = new Date(), type = 'day', utc = false) {
  const dateParts = convertTimezone(date)
    .split(' ')[0]
    .split('-')

  if (type === 'day') {
    if (utc) {
      return Number(`${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}`)
    }

    return Number(`${dateParts[0] + dateParts[1] + dateParts[2]}`)
  }
  if (type === 'month') {
    if (utc) {
      return Number(`${date.getFullYear()}${date.getMonth() + 1}`)
    }

    return Number(`${dateParts[0] + dateParts[1]}`)
  }
  if (type === 'year') {
    if (utc) {
      return Number(`${date.getFullYear()}`)
    }

    return Number(`${dateParts[0]}`)
  }

  const dateObj = date.toISOString()

  return Number(dateObj.replace(/\D/g, ''))
}

// Gets X Days Ago from the Beginning of that day (today default). Counts current day as one of the days. Get the first day fully if "full = true"
function getDaysAgo(days, date = new Date(), full = false) {
  const dateObj = new Date(date.getTime())
  dateObj.setDate(dateObj.getDate() - days)
  if (!full) {
    return dateObj
  }
  const firstDay = new Date(dateObj.getFullYear(), dateObj.getMonth(), 1)

  return firstDay
}

const timeZoneList = [
  { text: 'Use Browser Local Time', value: 'UseLocalTime' },
  { text: '(UTC-5) Eastern Standard Time', value: 'America/New_York' },
  { text: '(UTC-6) Central Standard Time', value: 'America/Chicago' },
  { text: '(UTC-7) Mountain Standard Time', value: 'America/Denver' },
  { text: '(UTC-7) Mountain Standard Time (Arizona)', value: 'America/Phoenix' },
  { text: '(UTC-8) Pacific Standard Time', value: 'America/Los_Angeles' },
  { text: '(UTC-9) Alaska Standard Time', value: 'America/Anchorage' },
  { text: '(UTC-10) Hawaii Standard Time', value: 'Pacific/Honolulu' },
]

const timeZoneAbbr = {
  EST: 'Eastern Standard Time',
  EDT: 'Eastern Daylight Time',
  CST: 'Central Standard Time',
  CDT: 'Central Daylight Time',
  MST: 'Mountain Standard Time',
  MDT: 'Mountain Daylight Time',
  PST: 'Pacific Standard Time',
  PDT: 'Pacific Daylight Time',
}

// eslint-disable-next-line object-curly-newline
export {
  convertTimezone,
  timeZoneOffset,
  dateArray,
  createCronFromDate,
  dateOffset,
  getDaysAgo,
  dateBlock,
  dateFromBlock,
  timeZoneList,
  timeZoneAbbr,
  cronConversion,
}
