// JSON Timestamps to Javascript Objects
// /////////////////////////////////////

async function convertJSONDates(rowsData) {
  let rows = rowsData.rows ? rowsData.rows.map(m => ({ ...m })) : rowsData.map(m => ({ ...m }))
  if (!rowsData?.timestampColumns) {
    const convertedTimestamps = []
    rows.forEach(row => {
      const newRow = { ...row }
      Object.keys(row).forEach(key => {
        if (Date.parse(row[key])) newRow[key] = new Date(row[key])
      })
      convertedTimestamps.push(newRow)
    })

    return { queryId: rowsData.queryId, rows: convertedTimestamps }
  }

  // rowsData.timestampColumns Then Specified Timestamps are already designated and do not need to be found
  if (rowsData.timestampColumns && !rowsData.timestampColumns.length > 0) {
    delete rows.timestampColumns

    return { queryId: rowsData.queryId, rows }
  }

  if (rowsData.timestampColumns && rowsData.timestampColumns.length > 0) {
    rowsData.timestampColumns.forEach(column => {
      rows = rows.map(m => ({ ...m, [column]: m[column] ? new Date(m[column]) : null }))
    })

    delete rows.timestampColumns

    return { queryId: rowsData.queryId, rows }
  }

  return null
}
async function anotherFunction() {
  console.log('Another Function')
}

export { convertJSONDates, anotherFunction }
