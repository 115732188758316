<!-- Create New App Password -->
<template>
  <div>
    <v-card-actions>
      <v-row>
        <v-col
          sm="12"
        >
          <v-alert
            border="left"
            colored-border
            text
            color="primary"
          >
            <strong>Let's setup your Snowflake System account for {{ serverConfig.name }}.</strong>
          </v-alert>
          <v-alert
            color="primary"
            dark
            :icon="icons.mdiBadgeAccountOutline"
          >
            <strong>You will need ACCOUNTADMIN privileges to complete the following setup steps.</strong>
          </v-alert>
          <v-stepper
            v-model="activeStep"
            class="custom-header"
          >
            <!-- Header -->
            <v-stepper-header>
              <!-- Header: Step 1 -->
              <v-stepper-step
                :complete="activeStep > 1"
                step="1"
              >
                <div class="d-flex align-center">
                  <!-- <span class="text--primary text-4xl font-weight-bold me-3">01</span> -->
                  <div class="d-flex flex-column">
                    <span class="text--primary text-sm font-weight-semibold">Snowflake System Account</span>
                    <span class="text--secondary text-xs">Connect Your Snowflake System Account</span>
                  </div>
                </div>
              </v-stepper-step>

              <v-divider></v-divider>

              <!-- Header: Step 2 -->
              <v-stepper-step
                :complete="activeStep > 2"
                step="2"
              >
                <div class="d-flex align-center">
                  <div class="d-flex flex-column">
                    <span class="text--primary text-sm font-weight-semibold">System Account Settings</span>
                    <span class="text--secondary text-xs">Update System Account Settings</span>
                  </div>
                </div>
              </v-stepper-step>

              <v-divider></v-divider>

              <!-- Header: Step 3 -->
              <v-stepper-step step="3">
                <div class="d-flex align-center">
                  <div class="d-flex flex-column">
                    <span class="text--primary text-sm font-weight-semibold">Setup Schema and Integrations</span>
                    <span class="text--secondary text-xs">Setup {{ serverConfig.name }} Schema Integrations</span>
                  </div>
                </div>
              </v-stepper-step>
            </v-stepper-header>

            <!-- Stepper Items -->
            <v-stepper-items>
              <!-- Stepper Content: Step 1 -->
              <v-stepper-content step="1">
                <system-snowflake-account
                  v-if="account && user"
                  :account="account"
                  :user="user"
                  @next="activeStep = 2"
                >
                </system-snowflake-account>
              </v-stepper-content>

              <!-- Stepper Content: Step 2 -->
              <v-stepper-content step="2">
                <system-configuration-settings
                  v-if="account"
                  :account="account"
                  @next="activeStep = 3"
                  @previous="activeStep = 1"
                ></system-configuration-settings>
              </v-stepper-content>
              <!-- Stepper Content: Step 3 -->
              <v-stepper-content step="3">
                <system-schema-creation
                  v-if="account && account.snowflakeCredentials"
                  :account="account"
                  @previous="activeStep = 2"
                  @finishSetup="finishSetup"
                >
                </system-schema-creation>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
      <v-form
        ref="form"
        v-model="valid"
      >
      </v-form>
    </v-card-actions>
  </div>
</template>

<script>
import { initiateFirstTimeLoads } from '@/functions/accountSetup'
import { initiateAllRefreshes } from '@/functions/initiateRefreshes'
import store from '@/store'
import { useRouter } from '@core/utils'
import { required } from '@core/utils/validation'
import { mdiBadgeAccountOutline } from '@mdi/js'
import serverConfig from '@serverConfig'
import { computed, onMounted, ref } from '@vue/composition-api'
import SystemConfigurationSettings from './SystemConfigurationSettings.vue'
import SystemSchemaCreation from './SystemSchemaCreation.vue'
import SystemSnowflakeAccount from './SystemSnowflakeAccount.vue'

export default {
  components: {
    SystemSnowflakeAccount,
    SystemSchemaCreation,
    SystemConfigurationSettings,
  },
  setup() {
    const { router } = useRouter()

    const activeStep = ref(1)
    const account = computed(() => store.state.accountProfile)
    const user = computed(() => store.state.user)
    const loadingFinish = ref(false)

    const finish = async () => {
      loadingFinish.value = true

      // Write Snowflake Values and Redirect
      loadingFinish.value = false

      // If System Account Needed?
      if (!account.value.snowflakeCredentials) {
        console.log('Need to Set Up System Account...')
        router.push('/account-setup/system-account/')
      } else {
        console.log('Navigating to Snowflake Main Page...')
        router.push('/')
      }
    }

    const finishSetup = () => {
      const { snowflakeBookmarks } = store?.state?.snowflakeData
      if (!snowflakeBookmarks || !snowflakeBookmarks.snowflakeQueryHistoryLoaded) {
        initiateFirstTimeLoads()
        initiateAllRefreshes()
      }
      router.push('/')
    }

    const proceedToFinish = () => {
      activeStep.value = 3
    }

    // Setting New App Password
    const valid = ref(true)
    const form = ref(null)

    const validateForm = () => {
      form.value.validate()
    }

    onMounted(() => {
      if (!localStorage.getItem('loggedIn')) {
        console.log('User Not Logged In! Cannot proceed with User Setup')
        router.push('/')
      }
      if (store.state.goToStep) {
        activeStep.value = store.state.goToStep
        store.commit('setGoToStep', null)
      }
    })

    return {
      finishSetup,
      proceedToFinish,
      loadingFinish,
      finish,
      user,
      account,
      activeStep,
      serverConfig,
      valid,
      form,
      validateForm,
      required,
      SystemSnowflakeAccount,
      SystemSchemaCreation,
      SystemConfigurationSettings,
      icons: {
        mdiBadgeAccountOutline,
      },
    }
  },
}
</script>
