/* eslint-disable import/prefer-default-export */
import { functions, httpsCallable } from '@/firebaseApp'
import { getTokens } from '@/functions/snowflakeOauth'
import { convertJSONDates } from './helpers'

async function snowflakeQuery(query, runOn = 'user') {
  const account = JSON.parse(localStorage.getItem('accountProfile'))
  const user = JSON.parse(localStorage.getItem('user'))

  // Calling the Query Cloud Function
  const sendToFunction = httpsCallable(functions, 'snowflakeQueryCall')
  try {
    const payload = {
      query,
      account,
    }
    if (runOn !== 'account') {
      payload.user = user
      if (localStorage.getItem('snowflakeAccessToken')) {
        const accessToken = await getTokens(account) // Get Access Token or New Token if Needed
        const oauth = {
          accessToken,
          snowflakeAccountId: localStorage.getItem('snowflakeAccountId'),
          snowflakeUsername: localStorage.getItem('snowflakeUsername'),
          snowflakeRole: localStorage.getItem('snowflakeScope').split('role:')[1],
        }
        payload.oauth = oauth
      }
    }
    const response = await sendToFunction(payload)
    if (typeof response.data === 'string' || response.data.message) {
      throw response.data.message || response.data
    }

    // Fix JSON dates to JS Objects
    const snowflakeRows = await convertJSONDates(response.data)

    return snowflakeRows
  } catch (err) {
    const errorMessage = `Snowflake Error: ${err}`
    console.log(errorMessage)

    throw errorMessage
  }
}

export { snowflakeQuery }
