/* eslint-disable no-loop-func */
/* eslint-disable no-plusplus */
import { updateSnowflakeBookmarks } from '@/firestore'
import { cloudFunction, dbLog } from '@/functions'
import { dateOffset } from '@/functions/dates'
import { snowflakeQuery } from '@/snowflake'
import store from '@/store'

const account = store.state.accountProfile || JSON.parse(localStorage.getItem('accountProfile'))
const executionsMinuteInterval = account?.jobs?.snowflakeExecutionsMinuteInterval || 60

function buildRoleHierarchy() {
  const roles = store.state.snowflakeData.snowflakeRoles
  const items = []

  // Build Parent Child Array Items
  roles.forEach(role => {
    if (role.granteeRoles.length === 0) {
      items.push({ name: role.name, parent: '' })
    } else {
      role.granteeRoles.forEach(grantee => {
        items.push({ name: role.name, parent: grantee })
      })
    }
  })

  const objectHier = {}

  const topParents = items.filter(f => f.parent === '')
  topParents.forEach(parent => {
    objectHier[parent.name] = {}
  })

  // Pass 01
  Object.keys(objectHier).forEach(parent => {
    const children = items.filter(f => f.parent === parent)
    children.forEach(child => {
      objectHier[parent] = { ...objectHier[parent], [child.name]: {} }
    })
  })

  // Pass 02
  Object.keys(objectHier).forEach(grandParent1 => {
    Object.keys(objectHier[grandParent1]).forEach(parent => {
      const children = items.filter(f => f.parent === parent)
      children.forEach(child => {
        objectHier[grandParent1][parent] = { ...objectHier[grandParent1][parent], [child.name]: {} }
      })
    })
  })

  // Pass 03
  Object.keys(objectHier).forEach(grandParent1 => {
    Object.keys(objectHier[grandParent1]).forEach(grandParent2 => {
      Object.keys(objectHier[grandParent1][grandParent2]).forEach(parent => {
        const children = items.filter(f => f.parent === parent)
        children.forEach(child => {
          objectHier[grandParent1][grandParent2][parent] = { ...objectHier[grandParent1][grandParent2][parent], [child.name]: {} }
        })
      })
    })
  })

  // Pass 04
  Object.keys(objectHier).forEach(grandParent1 => {
    Object.keys(objectHier[grandParent1]).forEach(grandParent2 => {
      Object.keys(objectHier[grandParent1][grandParent2]).forEach(grandParent3 => {
        Object.keys(objectHier[grandParent1][grandParent2][grandParent3]).forEach(parent => {
          const children = items.filter(f => f.parent === parent)
          children.forEach(child => {
            objectHier[grandParent1][grandParent2][grandParent3][parent] = { ...objectHier[grandParent1][grandParent2][grandParent3][parent], [child.name]: {} }
          })
        })
      })
    })
  })

  // Pass 05
  Object.keys(objectHier).forEach(grandParent1 => {
    Object.keys(objectHier[grandParent1]).forEach(grandParent2 => {
      Object.keys(objectHier[grandParent1][grandParent2]).forEach(grandParent3 => {
        Object.keys(objectHier[grandParent1][grandParent2][grandParent3]).forEach(grandParent4 => {
          Object.keys(objectHier[grandParent1][grandParent2][grandParent3][grandParent4]).forEach(parent => {
            const children = items.filter(f => f.parent === parent)
            children.forEach(child => {
              objectHier[grandParent1][grandParent2][grandParent3][grandParent4][parent] = { ...objectHier[grandParent1][grandParent2][grandParent3][grandParent4][parent], [child.name]: {} }
            })
          })
        })
      })
    })
  })

  // Pass 06
  Object.keys(objectHier).forEach(grandParent1 => {
    Object.keys(objectHier[grandParent1]).forEach(grandParent2 => {
      Object.keys(objectHier[grandParent1][grandParent2]).forEach(grandParent3 => {
        Object.keys(objectHier[grandParent1][grandParent2][grandParent3]).forEach(grandParent4 => {
          Object.keys(objectHier[grandParent1][grandParent2][grandParent3][grandParent4]).forEach(grandParent5 => {
            Object.keys(objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5]).forEach(parent => {
              const children = items.filter(f => f.parent === parent)
              children.forEach(child => {
                objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5][parent] = { ...objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5][parent], [child.name]: {} }
              })
            })
          })
        })
      })
    })
  })

  // Pass 07
  Object.keys(objectHier).forEach(grandParent1 => {
    Object.keys(objectHier[grandParent1]).forEach(grandParent2 => {
      Object.keys(objectHier[grandParent1][grandParent2]).forEach(grandParent3 => {
        Object.keys(objectHier[grandParent1][grandParent2][grandParent3]).forEach(grandParent4 => {
          Object.keys(objectHier[grandParent1][grandParent2][grandParent3][grandParent4]).forEach(grandParent5 => {
            Object.keys(objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5]).forEach(grandParent6 => {
              Object.keys(objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5][grandParent6]).forEach(parent => {
                const children = items.filter(f => f.parent === parent)
                children.forEach(child => {
                  objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5][grandParent6][parent] = { ...objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5][grandParent6][parent], [child.name]: {} }
                })
              })
            })
          })
        })
      })
    })
  })

  // Pass 08
  Object.keys(objectHier).forEach(grandParent1 => {
    Object.keys(objectHier[grandParent1]).forEach(grandParent2 => {
      Object.keys(objectHier[grandParent1][grandParent2]).forEach(grandParent3 => {
        Object.keys(objectHier[grandParent1][grandParent2][grandParent3]).forEach(grandParent4 => {
          Object.keys(objectHier[grandParent1][grandParent2][grandParent3][grandParent4]).forEach(grandParent5 => {
            Object.keys(objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5]).forEach(grandParent6 => {
              Object.keys(objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5][grandParent6]).forEach(grandParent7 => {
                Object.keys(objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5][grandParent6][grandParent7]).forEach(parent => {
                  const children = items.filter(f => f.parent === parent)
                  children.forEach(child => {
                    objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5][grandParent6][grandParent7][parent] = { ...objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5][grandParent6][grandParent7][parent], [child.name]: {} }
                  })
                })
              })
            })
          })
        })
      })
    })
  })

  // Pass 09
  Object.keys(objectHier).forEach(grandParent1 => {
    Object.keys(objectHier[grandParent1]).forEach(grandParent2 => {
      Object.keys(objectHier[grandParent1][grandParent2]).forEach(grandParent3 => {
        Object.keys(objectHier[grandParent1][grandParent2][grandParent3]).forEach(grandParent4 => {
          Object.keys(objectHier[grandParent1][grandParent2][grandParent3][grandParent4]).forEach(grandParent5 => {
            Object.keys(objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5]).forEach(grandParent6 => {
              Object.keys(objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5][grandParent6]).forEach(grandParent7 => {
                Object.keys(objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5][grandParent6][grandParent7]).forEach(grandParent8 => {
                  Object.keys(objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5][grandParent6][grandParent7][grandParent8]).forEach(parent => {
                    const children = items.filter(f => f.parent === parent)
                    children.forEach(child => {
                      objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5][grandParent6][grandParent7][grandParent8][parent] = { ...objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5][grandParent6][grandParent7][grandParent8][parent], [child.name]: {} }
                    })
                  })
                })
              })
            })
          })
        })
      })
    })
  })

  // Pass 10
  Object.keys(objectHier).forEach(grandParent1 => {
    Object.keys(objectHier[grandParent1]).forEach(grandParent2 => {
      Object.keys(objectHier[grandParent1][grandParent2]).forEach(grandParent3 => {
        Object.keys(objectHier[grandParent1][grandParent2][grandParent3]).forEach(grandParent4 => {
          Object.keys(objectHier[grandParent1][grandParent2][grandParent3][grandParent4]).forEach(grandParent5 => {
            Object.keys(objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5]).forEach(grandParent6 => {
              Object.keys(objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5][grandParent6]).forEach(grandParent7 => {
                Object.keys(objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5][grandParent6][grandParent7]).forEach(grandParent8 => {
                  Object.keys(objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5][grandParent6][grandParent7][grandParent8]).forEach(grandParent9 => {
                    Object.keys(objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5][grandParent6][grandParent7][grandParent8][grandParent9]).forEach(parent => {
                      const children = items.filter(f => f.parent === parent)
                      children.forEach(child => {
                        objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5][grandParent6][grandParent7][grandParent8][grandParent9][parent] = { ...objectHier[grandParent1][grandParent2][grandParent3][grandParent4][grandParent5][grandParent6][grandParent7][grandParent8][grandParent9][parent], [child.name]: {} }
                      })
                    })
                  })
                })
              })
            })
          })
        })
      })
    })
  })

  // Build childRoles
  const childRoles = {}

  // Get Starting Roles
  items.forEach(item => {
    childRoles[item.name] = []
  })

  Object.keys(childRoles).forEach(role => {
    // Add Self Role
    childRoles[role] = [...childRoles[role], ...[role]]

    // Level 1
    const level1 = items.filter(f => f.parent === role).map(m => m.name)
    childRoles[role] = [...childRoles[role], ...level1]
    level1.forEach(level1Value => {
      const level2 = items.filter(f => f.parent === level1Value).map(m => m.name)
      childRoles[role] = [...childRoles[role], ...level2]
      level2.forEach(level2Value => {
        const level3 = items.filter(f => f.parent === level2Value).map(m => m.name)
        childRoles[role] = [...childRoles[role], ...level3]
        level3.forEach(level3Value => {
          const level4 = items.filter(f => f.parent === level3Value).map(m => m.name)
          childRoles[role] = [...childRoles[role], ...level4]
          level4.forEach(level4Value => {
            const level5 = items.filter(f => f.parent === level4Value).map(m => m.name)
            childRoles[role] = [...childRoles[role], ...level5]
            level5.forEach(level5Value => {
              const level6 = items.filter(f => f.parent === level5Value).map(m => m.name)
              childRoles[role] = [...childRoles[role], ...level6]
              level6.forEach(level6Value => {
                const level7 = items.filter(f => f.parent === level6Value).map(m => m.name)
                childRoles[role] = [...childRoles[role], ...level7]
                level7.forEach(level7Value => {
                  const level8 = items.filter(f => f.parent === level7Value).map(m => m.name)
                  childRoles[role] = [...childRoles[role], ...level8]
                  level8.forEach(level8Value => {
                    const level9 = items.filter(f => f.parent === level8Value).map(m => m.name)
                    childRoles[role] = [...childRoles[role], ...level9]
                    level9.forEach(level9Value => {
                      const level10 = items.filter(f => f.parent === level9Value).map(m => m.name)
                      childRoles[role] = [...childRoles[role], ...level10]
                    })
                  })
                })
              })
            })
          })
        })
      })
    })
  })

  // Clean Arrays
  Object.keys(childRoles).forEach(role => {
    childRoles[role] = [...new Set(childRoles[role])]
  })

  return { objectHier, childRoles }
}

// const timeZone = JSON.parse(localStorage.getItem('timeZone'))

// Get Current Grants
async function refreshSnowflakeGrants() {
  const payload = {
    functionName: 'refreshSnowflakeGrants',
    payload: {
      account,
    },
  }
  await cloudFunction(payload)
}

async function refreshSnowflakeValidationReports() {
  try {
    const payload = {
      functionName: 'refreshSnowflakeValidationReports',
      payload: {
        account,
      },
    }
    await cloudFunction(payload)

    return 'Snowflake Validation Reports Refresh Successful'
  } catch (error) {
    console.log(error)
    store.dispatch('systemMessages', `<strong>${error}</strong>`)
    throw error
  }
}

// Snowflake Executions ///////////////
// //////////////////// //////////////

// sentPayload can contain { daysBack: 5 } optional

async function refreshSnowflakeExecutions(sentPayload) {
  try {
    store.commit('setLoadingBannerState', { key: 'executions', value: true })
    let table = 'snowflake.account_usage.query_history'

    // Check if Cache Schema Available
    if (account.snowflakeSettings?.ownershipSchema) {
      table = `${account.snowflakeSettings.ownershipSchemaDatabase}.${account.snowflakeSettings.ownershipSchema}.query_history_table`
    }
    const payload = {
      functionName: 'executionData',
      payload: {
        table,
        account,
        writeToDB: true,
        minuteInterval: executionsMinuteInterval,
      },
    }
    if (sentPayload?.daysBack) {
      payload.payload.startDate = dateOffset('days', sentPayload.daysBack * -1, new Date(), true)
      payload.payload.endDate = new Date()
    }
    await cloudFunction(payload)
    store.commit('setLoadingBannerState', { key: 'executions', value: false })

    return 'Snowflake Executions Refresh Successful'
  } catch (error) {
    store.commit('setLoadingBannerState', { key: 'executions', value: false })
    console.log(error)
    store.dispatch('systemMessages', `<strong>${error}</strong>`)
    throw error
  }
}

async function getSnowflakeExecutions(payload) {
  try {
    let table = 'snowflake.account_usage.query_history'

    // Check if Cache Schema Available
    if (account.snowflakeSettings?.ownershipSchema) {
      table = `${account.snowflakeSettings.ownershipSchemaDatabase}.${account.snowflakeSettings.ownershipSchema}.query_history_table`
    }
    if (payload?.table) table = payload.table
    store.commit('setLoadingBannerState', { key: 'executions', value: true })

    // Check if Cache Schema Available
    if (account.snowflakeSettings?.ownershipSchema) {
      table = `${account.snowflakeSettings.ownershipSchemaDatabase}.${account.snowflakeSettings.ownershipSchema}.query_history_table`
    }
    const sendPayload = { account, table }
    if (payload?.account) sendPayload.account = payload.account
    if (payload?.writeToDB) sendPayload.writeToDB = payload.writeToDB
    if (payload?.startDate) sendPayload.startDate = payload.startDate
    if (payload?.endDate) sendPayload.endDate = payload.endDate
    if (payload?.groupBy) sendPayload.groupBy = payload.groupBy
    if (payload?.values) sendPayload.values = payload.values
    const functionPayload = { functionName: 'executionData', payload: sendPayload }
    const response = await cloudFunction(functionPayload)
    store.commit('setLoadingBannerState', { key: 'executions', value: false })

    return response
  } catch (error) {
    store.commit('setLoadingBannerState', { key: 'executions', value: false })
    console.log(error)
    store.dispatch('systemMessages', `<strong>${error}</strong>`)
    throw error
  }
}

// Snowflake Roles ///////////////////
// //////////////////// //////////////

async function refreshSnowflakeRoles() {
  // Refresh Snowflake Users
  const payload = {
    functionName: 'refreshSnowflakeRoles',
    payload: {
      account,
    },
  }
  await cloudFunction(payload)
}

async function refreshSnowflakeUsersAndRoles() {
  try {
    store.commit('setLoadingBannerState', { key: 'users', value: true })
    const promises = []
    store.dispatch('snowflakeData/refreshSnowflakeUsers')
    promises.push(refreshSnowflakeRoles())
    await Promise.all(promises)
    store.commit('setLoadingBannerState', { key: 'users', value: false })

    return 'Snowflake User Refresh Successful'
  } catch (error) {
    store.dispatch('systemMessages', `<strong>${error}</strong>`)
    throw error
  }
}

// Refresh Snowflake Consumption Data To Database
async function executeQueryHistoryBackfill(startBeforeTimestamp) {
  if (!startBeforeTimestamp) {
    const errorMessage = 'No startBeforeTimestamp argument was provided and is required'
    throw errorMessage
  }
  const snowflakeBookmarks = store?.state?.snowflakeData?.snowflakeBookmarks?.snowflakeQueryHistoryLoaded
  if (snowflakeBookmarks) {
    console.log('Found Previous Query History Load... Clearing Bookmark...')
    await updateSnowflakeBookmarks({ name: 'snowflakeQueryHistoryLoaded', value: null })
  }
  await updateSnowflakeBookmarks({ name: 'snowflakeQueryHistoryStarted', value: Date.now() })

  const payload = {
    functionName: 'queryHistoryBackfill',
    payload: {
      account,
      startBeforeTimestamp,
    },
  }
  await cloudFunction(payload)

  return 'Query History Backfill Successful'
}

// Snowflake Actions
// /////////////////

async function alterSnowflakeUser(user, property, value) {
  const response = await snowflakeQuery(`ALTER USER "${user}" SET ${property} = ${value};`)
  refreshSnowflakeUsersAndRoles()
  await dbLog({
    alteredSnowflakeUser: user,
    alteredProperty: property,
    alteredValue: value,
    logType: 'alterSnowflakeUser',
    timestamp: new Date(),
  })

  return response
}

async function createSnowflakeUser(payload) {
  let query = `
  CREATE USER ${payload.username} login_name = ${payload.username} password = '${payload.password}' email = '${payload.email}' first_name = '${payload.firstName}' last_name = '${payload.lastName}' display_name = '${payload.firstName} ${payload.lastName}' default_role='${payload.role}' default_warehouse='${payload.warehouse}'`
  if (payload.tempPassword) query = `${query} must_change_password = true`
  if (payload.namespace) query = `${query} default_namespace = ${payload.namespace}`
  console.log('SENDING QUERY', query)
  try {
    // Create User
    const createSnowflakeUserResponse = await snowflakeQuery(query)

    // Grant Role
    await snowflakeQuery(`grant role ${payload.role} to user ${payload.username};`)
    await dbLog({
      createdSnowflakeUser: payload.username,
      query,
      logType: 'createSnowflakeUser',
      timestamp: new Date(),
    })
    refreshSnowflakeUsersAndRoles()

    return createSnowflakeUserResponse
  } catch (err) {
    console.log(err)
    throw err
  }
}

// eslint-disable-next-line object-curly-newline
export { buildRoleHierarchy, refreshSnowflakeExecutions, getSnowflakeExecutions, createSnowflakeUser, alterSnowflakeUser, refreshSnowflakeGrants, refreshSnowflakeUsersAndRoles, refreshSnowflakeRoles, refreshSnowflakeValidationReports, executeQueryHistoryBackfill }
