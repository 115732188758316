import { auth } from '@/firebaseApp'
import { readDoc, writeDoc } from '@/firestore'
import { dbLog, encryptData } from '@/functions'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile } from 'firebase/auth'

function generateRandomPassword(charLength) {
  const length = charLength ? Number(charLength) : 8
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!$&*^'
  let retVal = ''
  for (let i = 0, n = charset.length; i < length; i += 1) {
    retVal += charset.charAt(Math.floor(Math.random() * n))
  }

  return retVal
}

function appUserSignin(email, password) {
  return new Promise((res, rej) => {
    signInWithEmailAndPassword(auth, email, password)
      .then(user => {
        if (user) {
          dbLog({ user: { id: user.user.uid }, logType: 'loginAttempts', successful: true })

          return res(user)
        }
        const err = 'Could not sign in.'
        throw rej(err)
      })
      .catch(error => {
        console.log('Failed to Login', error)
        throw rej(error)
      })
  })
}

// Can be used to update Firebase Auth with payload: { displayName: '1' }
async function updateFirebaseAuth(payload) {
  updateProfile(auth.currentUser, payload)
    .then(() => {})
    .catch(error => {
      console.log('An Error Occurred While Trying To Update The Firebase User Profile: ', error)
    })
}

// Async Update For CreateAppUser
async function setNewAppUser(payload, userUpdates) {
  console.log('payload', payload)
  console.log('userUpdates', userUpdates)

  const userObject = userUpdates

  // Encrypt New Password
  const encryptedPassword = await encryptData(payload.password)
  console.log('encryptedPassword', encryptedPassword)

  userObject.password = encryptedPassword.data
  userObject.lastLogin = new Date()

  try {
    const response = await writeDoc({
      account: { id: payload.accountId },
      collection: 'users',
      docId: userObject.id,
      data: userObject,
    })

    return response
  } catch (err) {
    const errorMessage = `Failed to Create User Object: ${err}`
    console.log(errorMessage)
    throw errorMessage
  }
}

// Create App User From Temp Password or OAuth Route
async function createAppUser(payload) {
  // eslint-disable-next-line object-curly-newline
  const { email, password, isNewAccount, firstName, lastName } = payload
  // eslint-disable-next-line object-curly-newline
  let { accountId, role, source, created } = payload
  if (!source) source = 'password'
  localStorage.setItem('newUser', source)

  return new Promise((res, rej) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(async userCredential => {
        if (!role) role = localStorage.getItem('accountDefaultRole')
        if (!accountId) accountId = localStorage.getItem('accountId')
        if (!created) created = new Date()
        let userObject = {
          accounts: [accountId],
          defaultAccount: accountId,
          id: userCredential.user.uid,
          role,
          created,
          modified: new Date(),
          enabled: true,
        }
        console.log('USEROBJECT', userObject)

        if (isNewAccount) {
          const newAccountProps = {
            email,
            isPrimaryContact: true,
            firstName,
            lastName,
            timeZone: 'UseLocalTime',
          }
          userObject = { ...userObject, ...newAccountProps }
          console.log('USEROBJECT', userObject)
        }
        updateFirebaseAuth({ displayName: accountId })
        console.log('Update Firbase Auth')

        // Remove from Payload to write to DB
        // Get Payload from waitingOnAccountCreation
        if (!isNewAccount) {
          const tempProfile = await readDoc({
            account: { id: accountId },
            collection: 'users',
            docId: 'waitingOnAccountCreation',
            subCollection: 'waitingOnAccountCreation',
            subCollectionDocId: email,
          })
          console.log('TempProfile', tempProfile)

          // Remove Unneeded Items from Profile
          delete tempProfile.tempPassword
          userObject = { ...userObject, ...tempProfile }
        }
        console.log('Entering setNewAppUser')
        await setNewAppUser(payload, userObject)

        return res('Successfully Created User Authorization')
      })
      .catch(error => {
        localStorage.removeItem('newUser')
        const errorMessage = `There was an error creating the user: ${email}: ${error}`
        throw rej(errorMessage)
      })
  })
}

// eslint-disable-next-line object-curly-newline
export { createAppUser, updateFirebaseAuth, appUserSignin, generateRandomPassword }
