<template>
  <div>
    <v-card
      outlined
      class="mb-8 pa-5"
    >
      <v-row>
        <v-col sm="12">
          <v-alert
            color="primary"
            dark
            :icon="icons.mdiDatabase"
          >
            <strong>Create a {{ serverConfig.name }} managed Schema.</strong>
          </v-alert>
        </v-col>
        <v-col sm="12">
          <v-alert
            border="left"
            color="primary"
            dark
            text
          >
            <strong>Some of Snowflake's account management views can take a very long time to run (such as the Query History view), creating a {{ serverConfig.name }} Schema that is automatically
              managed to materialize views allows fast running tables automatically.</strong>
          </v-alert>
        </v-col>

        <!-- Create a Role -->
        <v-divider class="my-5"></v-divider>
        <v-col sm="12">
          <v-alert
            color="primary"
            dark
          >
            <strong>01 - Create Schema and Ownership</strong>
          </v-alert>
        </v-col>
        <v-col sm="12">
          <v-alert
            border="left"
            color="primary"
            dark
            text
          >
            <strong>Choose a database and schema name and we will help generate a script for the new Schema and correct permissions.</strong>
          </v-alert>
        </v-col>
        <v-col
          sm="12"
          md="6"
        >
          <v-text-field
            v-model="snowflakeDatabase"
            outlined
            :label="`Snowflake Database`"
            :rules="[() => !!snowflakeDatabase || 'Username is required']"
            hide-details="auto"
            @focus="resetErrors"
          ></v-text-field>
        </v-col>
        <v-col
          sm="12"
          md="6"
        >
          <v-text-field
            v-model="snowflakeSchema"
            outlined
            :label="`Snowflake Schema for ${serverConfig.name}`"
            :rules="[() => !!snowflakeSchema || 'Password is required']"
            hide-details="auto"
            @focus="resetErrors"
          ></v-text-field>
        </v-col>
        <v-col sm="12">
          <v-alert
            border="left"
            color="primary"
            dark
            text
          >
            <strong>Run the following script to create the Schema and Ownership. The script assumes that the database {{ snowflakeDatabase }} already exists.</strong>
          </v-alert>
        </v-col>
        <v-col
          v-if="!snowflakeDatabase || !snowflakeSchema"
          sm="12"
        >
          <v-alert
            type="error"
            dark
            text
          >
            <strong>All Fields Above must be completed to generate script.</strong>
          </v-alert>
        </v-col>
      </v-row>
      <pre v-if="snowflakeDatabase && snowflakeSchema && props.account.snowflakeCredentials.role">
      <code>
        create schema {{ snowflakeDatabase }}.{{ snowflakeSchema }};
        grant usage on database {{ snowflakeDatabase }} to role {{ props.account.snowflakeCredentials.role }};
        grant ownership on schema {{ snowflakeDatabase }}.{{ snowflakeSchema }} to {{ props.account.snowflakeCredentials.role }};
      </code>
    </pre>
      <v-divider class="my-5"></v-divider>
      <!-- Create a Role -->
      <v-row
        v-if="snowflakeDatabase && snowflakeSchema && props.account.snowflakeCredentials.role"
        class="mt-5 pb-5"
      >
        <v-col sm="12">
          <v-alert
            color="primary"
            dark
          >
            <strong>02 - Validate {{ serverConfig.name }} Schema</strong>
          </v-alert>
        </v-col>
        <v-col sm="12">
          <v-alert
            border="left"
            color="primary"
            dark
            text
          >
            <strong>After Successful Run of Script: Time to verify everything is working correctly.</strong>
          </v-alert>
        </v-col>
        <v-btn
          v-if="!schemaValidationSuccessful"
          :loading="loading"
          block
          color="info"
          class="mt-5"
          @click="validateSchemaOwnership"
        >
          <strong>Validate Schema Ownership</strong>
        </v-btn>
        <v-btn
          v-if="schemaValidationSuccessful"
          block
          class="mt-5"
          color="success"
        >
          Schema Ownership Validated
        </v-btn>
        <v-col
          v-if="validateError"
          sm="12"
        >
          <v-alert type="error">
            {{ validateError }}
          </v-alert>
        </v-col>
      </v-row>
      <!-- API Integration Setup Section -->
      <!-- ----------------------------- -->
      <template v-if="schemaValidationSuccessful">
        <v-row v-if="true">
          <!-- Create a Role -->
          <v-divider class="my-5"></v-divider>
          <v-col sm="12">
            <v-alert
              color="primary"
              dark
            >
              <strong>03 - Create {{ serverConfig.name }} / Snowflake Integration</strong>
            </v-alert>
          </v-col>
          <v-col sm="12">
            <v-alert
              border="left"
              color="primary"
              dark
              text
            >
              <strong>Snowflake can communicate with {{ serverConfig.name }} using an API integration which we will setup now. Run the following script in Snowflake to create the integration.</strong>
            </v-alert>
          </v-col>
        </v-row>
        <pre>
      <code>
        create or replace api integration snowflow_api
        api_provider = google_api_gateway
        google_audience = 'snowflake-external-api-154onxjxay572.apigateway.snowplow-01.cloud.goog'
        api_allowed_prefixes = ('https://snowflake-external-gateway-8d0joc4z.uc.gateway.dev')
        enabled = true
        comment = 'External API Integration for {{ serverConfig.name }} Application';

        grant usage on integration snowflow_api to role {{ props.account.snowflakeCredentials.role }};
      </code>
    </pre>
        <v-row>
          <v-col sm="12">
            <v-alert
              border="left"
              color="primary"
              dark
              text
            >
              <strong>After successful execution of the scripts above. Run the following script and copy the API_GCP_SERVICE_ACCOUNT "property_value" into the field below.</strong>
            </v-alert>
          </v-col>
        </v-row>
        <pre>
      <code>
        describe integration snowflow_api;
      </code>
    </pre>
        <v-row>
          <v-col
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="apiGCPServiceAccount"
              outlined
              :label="`API_GCP_SERVICE_ACCOUNT`"
              :rules="[() => !!apiGCPServiceAccount || 'API_GCP_SERVICE_ACCOUNT is required']"
              hide-details="auto"
              @focus="resetErrors"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <div class="d-flex justify-space-between mt-5">
        <v-btn
          outlined
          @click="previous"
        >
          Previous
        </v-btn>
        <v-btn
          :disabled="!integrationValidationSuccessful"
          color="primary"
          @click="finish"
        >
          Finish
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
import { updateDoc } from '@/firestore'
import { cloudFunction } from '@/functions'
import { required } from '@core/utils/validation'
import { mdiDatabase } from '@mdi/js'
import serverConfig from '@serverConfig'
import { computed, onMounted, ref } from '@vue/composition-api'

export default {
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const snowflakeDatabase = ref(null)
    const snowflakeSchema = ref(null)
    const validateError = ref(null)
    const loading = ref(false)
    const schemaValidationSuccessful = ref(null)
    const apiGCPServiceAccount = ref(null)

    onMounted(() => {
      if (props.account.snowflakeSettings) {
        const settings = props.account.snowflakeSettings
        if (settings.ownershipSchema) snowflakeSchema.value = settings.ownershipSchema
        if (settings.ownershipSchemaDatabase) snowflakeDatabase.value = settings.ownershipSchemaDatabase
        if (settings.api_gcp_service_account) apiGCPServiceAccount.value = settings.api_gcp_service_account
      }
    })

    const integrationValidationSuccessful = computed(() => {
      let value = false
      if (apiGCPServiceAccount.value) {
        value = true
      } else {
        value = false
      }

      return value
    })

    const writeGCPAccount = async () => {
      let { snowflakeSettings } = props.account
      snowflakeSettings = {
        ...snowflakeSettings,
        api_gcp_service_account: apiGCPServiceAccount.value,
      }
      await updateDoc({
        collection: 'accounts',
        docId: props.account.id,
        data: {
          snowflakeSettings,
        },
      })
    }

    const writeSchemaToDB = async () => {
      let { snowflakeSettings } = props.account
      snowflakeSettings = {
        ...snowflakeSettings,
        ownershipSchemaDatabase: snowflakeDatabase.value,
        ownershipSchema: snowflakeSchema.value,
      }
      await updateDoc({
        collection: 'accounts',
        docId: props.account.id,
        data: {
          snowflakeSettings,
        },
      })
    }

    const resetErrors = () => {
      validateError.value = null
    }

    const validateSchemaOwnership = async () => {
      console.log('Validating Schema Ownership....')
      loading.value = true
      resetErrors()
      try {
        const createResponse = await cloudFunction({
          functionName: 'createValidationTable',
          payload: { account: props.account, database: snowflakeDatabase.value, schema: snowflakeSchema.value },
        })

        // const createResponse = await snowflakeQuery(createTableQuery, 'account')
        if (createResponse.data.rows) {
          console.log('Create Validation Table Successful...')
          await writeSchemaToDB()
          schemaValidationSuccessful.value = true
        } else {
          validateError.value = createResponse.data
        }
        loading.value = false
      } catch (err) {
        console.log(err)
        loading.value = true
      }
    }

    const previous = () => {
      emit('previous')
    }

    const finish = () => {
      writeGCPAccount()
      emit('finishSetup')
    }

    return {
      writeGCPAccount,
      finish,
      previous,
      loading,
      resetErrors,
      integrationValidationSuccessful,
      schemaValidationSuccessful,
      validateError,
      validateSchemaOwnership,
      apiGCPServiceAccount,
      snowflakeDatabase,
      snowflakeSchema,
      props,
      serverConfig,
      required,
      icons: {
        mdiDatabase,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
pre code {
  background-color: #000;
  border: 1px solid #000;
  display: block;
  padding: 20px;
}
</style>
