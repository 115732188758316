/* eslint-disable object-curly-newline */
/* eslint-disable no-plusplus */
import { functions, httpsCallable } from '@/firebaseApp'
import { writeDoc } from '@/firestore'
import { getTokens } from '@/functions/snowflakeOauth'

if (localStorage.getItem('accountProfile') === 'undefined') localStorage.removeItem('accountProfile')
let account = JSON.parse(localStorage.getItem('accountProfile'))

if (!account) {
  account = { id: localStorage.getItem('accountId') }
}

const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

// For DB Logging: Pass at a minimum Payload: { payload.logType } // loginAttempts
const dbLog = async payload => {
  const accountId = payload.accountId || payload.account?.id || account?.id
  const timestamp = payload.timestamp || new Date()

  // Parse Data Object from payload
  const data = { ...payload }

  // Add Additional Keys
  data.userId = payload.userId || user ? user.id : null
  data.timestamp = timestamp
  if (localStorage.getItem('snowflakeAccessToken')) {
    data.accessToken = await getTokens(account)
  }

  // Remove Unneeded Data
  if (payload.account) delete data.account
  if (data.user) delete data.user
  await writeDoc({
    accountId,
    collection: 'logs',
    docId: 'logs',
    subCollection: payload.logType,
    subCollectionDocId: Date.now(),
    data,
  })
}

// Get Color Light Variation
function colorLight(color, opacity = 0.3) {
  // coerce values so ti is between 0 and 1.
  const opacityCalc = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)

  return color + opacityCalc.toString(16).toUpperCase()
}

// Calling a Cloud Function - payload: { functionName: '', payload: {} }
const cloudFunction = async payload => {
  // eslint-disable-next-line no-useless-catch
  try {
    const functionPayload = payload.payload || {}
    if (localStorage.getItem('snowflakeAccessToken')) {
      const accessToken = await getTokens(account)
      const snowflakeAccountId = localStorage.getItem('snowflakeAccountId')
      const snowflakeUsername = localStorage.getItem('snowflakeUsername')
      functionPayload.oauth = { accessToken, snowflakeAccountId, snowflakeUsername }
    }
    if (!functionPayload.account) functionPayload.account = account
    if (!functionPayload.user && user) functionPayload.user = user
    const sendToFunction = httpsCallable(functions, 'callFunction')
    const response = await sendToFunction({
      functionName: payload.functionName,
      payload: functionPayload,
    })

    return response
  } catch (err) {
    throw err
  }
}

// Data Encryption - Using Cloud Functions
const encryptData = async text => {
  try {
    const response = await cloudFunction({ functionName: 'encryptData', payload: { text } })

    return response
  } catch (err) {
    const errorMessage = `There was an error encrypting data: ${err}`
    console.log(errorMessage)
    throw errorMessage
  }
}

// Data Encryption - Using Cloud Functions
const decryptData = async hash => {
  try {
    const response = await cloudFunction({ functionName: 'decryptData', payload: hash })

    return response
  } catch (err) {
    const errorMessage = `There was an error decrypting data: ${err}`
    console.log(errorMessage)
    throw errorMessage
  }
}

// Get Month Name or Abbreviation (monthNumber, abbr) // abbr only if abbr needed
// Valid to pass all the following values: YYYYMMDD, YYYYMM, MM, M
function getMonths(monthNumber, abbr) {
  let monthValue = monthNumber
  if (monthValue.toString().length > 4) monthValue = monthValue.toString().substring(4, 6)
  const getMonthIndex = Number(monthValue) - 1
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const monthAbbr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  if (abbr) {
    return monthAbbr[getMonthIndex]
  }

  return month[getMonthIndex]
}

function distributionHighMediumLow(value, distributionNumber) {
  if (value < distributionNumber) return 'Low'
  if (value >= distributionNumber && value < distributionNumber * 2) return 'Medium'

  return 'High'
}

// Number and Byte Formatters (i.e. 1563 = 1.56K)
const numberRanges = [
  { divider: 1e12, suffix: 'T' },
  { divider: 1e9, suffix: 'B' },
  { divider: 1e6, suffix: 'M' },
  { divider: 1e3, suffix: 'K' },
]

const byteRanges = [
  { divider: 1e18, suffix: ' EB' },
  { divider: 1e15, suffix: ' PB' },
  { divider: 1e12, suffix: ' TB' },
  { divider: 1e9, suffix: ' GB' },
  { divider: 1e6, suffix: ' MB' },
  { divider: 1e3, suffix: ' KB' },
]

function formatCurrency(n) {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  return currencyFormatter.format(n)
}

function formatNumbers(n) {
  for (let i = 0; i < numberRanges.length; i++) {
    if (n >= numberRanges[i].divider) {
      const divider = parseFloat((n / numberRanges[i].divider).toFixed(1))

      return divider.toString() + numberRanges[i].suffix
    }
  }

  return parseFloat(n.toFixed(1).toString())
}

function formatBytes(n) {
  for (let i = 0; i < byteRanges.length; i++) {
    if (n >= byteRanges[i].divider) {
      const divider = parseFloat((n / byteRanges[i].divider).toFixed(1))

      return divider.toString() + byteRanges[i].suffix
    }
  }

  return parseFloat(n.toFixed(1).toString())
}

function formatNumberSeparator(n) {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export {
  colorLight,
  cloudFunction,
  encryptData,
  decryptData,
  getMonths,
  formatCurrency,
  formatNumbers,
  formatBytes,
  distributionHighMediumLow,
  formatNumberSeparator,
  dbLog,
}
