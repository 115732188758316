import { cloudFunction } from '@/functions'
import { snowflakeQuery } from '@/snowflake'
import moment from 'moment-timezone'

// import { executeQueryHistoryBackfill } from '@/snowflake/snowflakeAdministration'

async function initiateFirstTimeLoads(account) {
  if (!account || !account.snowflakeSettings || !account.snowflakeSettings.ownershipSchemaDatabase) {
    const errorMessage = 'There is No Account Schema So First Time Initiation Can Not Begin!'
    throw errorMessage
  }
  const schema = `${account.snowflakeSettings.ownershipSchemaDatabase}.${account.snowflakeSettings.ownershipSchema}`

  try {
    // Note that most Snowflake Data Has First Time Load Capabilities Already if There Is No Bookmark Entry
    const promises = []
    console.log('Loading First Time Loads...')

    // Create Required Tables on Snowflake Schema
    // //////////////////////////////////////////

    // Create Query History Table in System Schema
    // Get Last Hour of Data As Starting Point
    const lastHourOfDataTimestamp = moment()
      .startOf('hour')
      .format()
    const createQueryHistoryTableQuery = `
  create or replace table ${schema}.query_history_table cluster by (end_time, start_time, user_name, warehouse_name)
  as select * from snowflake.account_usage.query_history where end_time >= ${lastHourOfDataTimestamp}::timestamp;`
    promises.push(snowflakeQuery(createQueryHistoryTableQuery, 'account'))
    console.log('QUERY_HISTORY_TABLE CREATED SUCCESSFULLY')

    // CREATE SF_SETTINGS TABLE ON SNOWFLAKE
    const createSettingsTableQuery = `CREATE TABLE IF NOT EXISTS ${schema}.SF_SETTINGS
  (type string, name string, value string, modified_at timestamp);`
    promises.push(snowflakeQuery(createSettingsTableQuery, 'account'))

    // CREATE SF_LOGS TABLE ON SNOWFLAKE
    const createLogsTableQuery = `CREATE TABLE IF NOT EXISTS ${schema}.SF_LOGS
  (type string, name string, value string, result string, modified_at timestamp);`
    promises.push(snowflakeQuery(createLogsTableQuery, 'account'))

    // SET UP SNOWFLAKE TABLE SCHEMA/FRESHNESS DATA
    promises.push(cloudFunction({ functionName: 'snowflakeFreshnessFirstTimeLoad', payload: { account } }))

    // Schema Table

    const promiseResponse = await Promise.all(promises)
    console.log('Response For Initial Creation: ', promiseResponse)

    // Do not wait for the following to complete as they will error out doing so
    // Setup Query History Execution
    // executeQueryHistoryBackfill(lastHourOfDataTimestamp) // Not using Promises As It Will Timeout

    // Initialize First Time Setup of Schemas, Schema Changes, and Table Freshness Refreshes
    cloudFunction({ functionName: 'snowflakeSchemaFirstTimeLoad' })

    // Initialize First Time Setup of Snowflake Storage Data
    cloudFunction({ functionName: 'snowflakeStorageFirstTimeLoad' })

    // Initialize First Time Snowflake Tasks Setup
    cloudFunction({ functionName: 'snowflakeTasksFirstTimeLoad' })

    console.log('Finished First Time Loads...')

    return promiseResponse
  } catch (err) {
    const errorMessage = `Failed Setting Up Initial Data Loads For Account Setup: ${err}`
    console.log(errorMessage)
    throw errorMessage
  }
}

// eslint-disable-next-line import/prefer-default-export
export { initiateFirstTimeLoads }
