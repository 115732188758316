<template>
  <div>
    <v-card
      outlined
      class="mb-8 pa-10"
    >
      <v-row>
        <v-col sm="12">
          <v-alert
            color="primary"
            dark
            :icon="icons.mdiAccountCircle"
          >
            <strong>Setup a Snowflake System account so that {{ serverConfig.name }} can complete background tasks.</strong>
          </v-alert>
        </v-col>
        <v-col sm="12">
          <v-alert
            border="left"
            color="primary"
            dark
            text
          >
            <strong>One of {{ serverConfig.name }}'s main advantages is it's ability to continue to provide near realtime data update for data freshness, anomaly detection,
              compliance rules, etc. To achieve these features {{ serverConfig.name }} needs an always available Snowflake read-only account with access to the SNOWFLAKE ACCOUNT USAGE database.</strong>
          </v-alert>
        </v-col>
      </v-row>

      <!-- Create a Role -->
      <v-row v-if="!sectionAlreadyCompleted">
        <v-divider class="my-5"></v-divider>
        <v-col sm="12">
          <v-alert
            color="primary"
            dark
            :icon="icons.mdiCog"
          >
            <span class="text--white text-2xl font-weight-bold me-3">01 - Create User, Role, and Password</span>
          </v-alert>
        </v-col>
        <v-col sm="12">
          <v-alert
            border="left"
            color="primary"
            dark
            text
          >
            <strong>Create a User, Role and Password with the following grants: (User, Role, and Password can be anything you choose)</strong>
          </v-alert>
        </v-col>

        <v-col
          sm="12"
          md="6"
        >
          <v-text-field
            v-model="snowflakeUsername"
            outlined
            :label="`Snowflake User Name for ${serverConfig.name}`"
            :rules="[() => !!snowflakeUsername || 'Username is required']"
            hide-details="auto"
            class="mt-6"
            @focus="resetLoginPasswordFields"
          ></v-text-field>
        </v-col>
        <v-col
          sm="12"
          md="6"
        >
          <v-text-field
            v-model="snowflakePassword"
            outlined
            :label="`Snowflake Password for ${serverConfig.name}`"
            :rules="[() => !!snowflakePassword || 'Password is required']"
            hide-details="auto"
            class="mt-6"
            @focus="resetLoginPasswordFields"
          ></v-text-field>
        </v-col>
        <v-col
          sm="12"
          md="6"
        >
          <v-text-field
            v-model="snowflakeRole"
            outlined
            :label="`Snowflake Role for ${serverConfig.name}`"
            :rules="[() => !!snowflakeRole || 'Role is required']"
            hide-details="auto"
            class="mt-6"
            @focus="resetLoginPasswordFields"
          ></v-text-field>
        </v-col>
        <v-col
          sm="12"
          md="6"
        >
          <v-text-field
            v-model="snowflakeWarehouse"
            outlined
            :label="`Default Warehouse for ${serverConfig.name}`"
            :rules="[() => !!snowflakeWarehouse || 'Warehouse is required']"
            hide-details="auto"
            class="mt-6"
            @focus="resetLoginPasswordFields"
          ></v-text-field>
        </v-col>
        <v-col sm="12">
          <v-alert
            border="left"
            color="primary"
            dark
            text
          >
            <strong>Run the following script to create the User, Role and Needed Grants</strong>
          </v-alert>
        </v-col>
        <v-col sm="12">
          <pre v-if="snowflakeUsername && snowflakePassword && snowflakeRole && snowflakeWarehouse">
            <code>
              create role "{{ snowflakeRole }}";
              grant usage on warehouse "{{ snowflakeWarehouse }}" to role "{{ snowflakeRole }}";
              grant imported privileges on database snowflake to role "{{ snowflakeRole }}";
              grant manage grants on account to role "{{ snowflakeRole }}";
              grant execute task on account to role "{{ snowflakeRole }}";
              create user "{{ snowflakeUsername }}" password="{{ snowflakePassword }}"
                          default_role = "{{ snowflakeRole }}" default_warehouse = "{{ snowflakeWarehouse }}";
              grant role "{{ snowflakeRole }}" to user "{{ snowflakeUsername }}";
            </code>
          </pre>
        </v-col>
        <v-col sm="12">
          <v-alert
            v-if="!snowflakeUsername || !snowflakePassword || !snowflakeRole || !snowflakeWarehouse"
            type="error"
            border="left"
            dark
            text
          >
            <strong>All Fields Above must be completed to generate script.</strong>
          </v-alert>
        </v-col>
        <!-- Create a Role -->
        <v-divider class="my-5"></v-divider>
        <v-col sm="12">
          <v-alert
            color="primary"
            dark
            :icon="icons.mdiCog"
          >
            <span class="text--white text-2xl font-weight-bold me-3">02 - Validate {{ serverConfig.name }} User</span>
          </v-alert>
        </v-col>
        <v-col sm="12">
          <v-alert
            border="left"
            color="primary"
            dark
            text
          >
            <strong>After Successful Run of Script: Time to verify everything is working correctly. If you used the script above, the credentials should already be added.</strong>
          </v-alert>
        </v-col>
      </v-row>
      <v-expansion-panels
        v-if="snowflakeUsername && snowflakePassword && snowflakeRole && snowflakeWarehouse && !sectionAlreadyCompleted"
        v-model="activeExpansionPanel"
        class="mt-5"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="primary--text">
              <v-icon
                left
                color="primary"
              >
                {{ icons.mdiKeyChainVariant }}
              </v-icon>
              <strong>Use Snowflake Credentials (Username/Password)</strong>
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- username/password login form -->
            <v-card-text>
              <v-text-field
                v-model="snowflakeUsername"
                outlined
                label="Snowflake Username"
                required
                hide-details="auto"
                class="mb-6"
                @focus="resetLoginPasswordFields"
              ></v-text-field>

              <v-text-field
                v-model="snowflakePassword"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                label="Snowflake Password"
                :error-messages="errorMessages.password"
                placeholder="Password"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                required
                hide-details="auto"
                class="mb-2"
                @click:append="isPasswordVisible = !isPasswordVisible"
                @focus="resetLoginPasswordFields"
              ></v-text-field>

              <v-btn
                v-if="passwordVerificationCheck !== 'Success'"
                :loading="loadingPasswordCheck"
                block
                color="primary"
                type="submit"
                class="mt-6"
                @click="verifyPasswordCredentials"
              >
                Verify Credentials
              </v-btn>
              <v-btn
                v-if="passwordVerificationCheck === 'Success'"
                block
                color="success"
                type="submit"
                class="mt-6"
              >
                Credentials Verified
              </v-btn>
            </v-card-text>
            <v-alert
              v-if="loginError"
              type="error"
            >
              {{ loginError }}
            </v-alert>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div
        v-if="sectionAlreadyCompleted"
        class="mt-5"
      >
        <v-alert
          color="success"
          dark
          :icon="icons.mdiAccountCircle"
        >
          <strong>Section already completed.</strong>
        </v-alert>
      </div>
      <div class="d-flex justify-end mt-5">
        <v-btn
          v-if="sectionAlreadyCompleted"
          class="mr-3"
          :disabled="!snowflakeAccountVerified"
          color="warning"
          @click="sectionAlreadyCompleted = false; snowflakeAccountVerified = false"
        >
          Setup Again
        </v-btn>
        <v-btn
          :disabled="!snowflakeAccountVerified"
          color="primary"
          @click="next"
        >
          Next
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
import { updateDoc } from '@/firestore'
import { encryptData } from '@/functions'
import { generateRandomPassword } from '@/functions/authentication'
import { snowflakeQuery } from '@/snowflake'
import { required } from '@core/utils/validation'
// eslint-disable-next-line object-curly-newline
import { mdiAccountCircle, mdiEyeOffOutline, mdiEyeOutline, mdiFormatLineStyle, mdiKeyChainVariant } from '@mdi/js'
import serverConfig from '@serverConfig'
import { onMounted, ref } from '@vue/composition-api'

export default {
  props: {
    account: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const sectionAlreadyCompleted = ref(false)
    const snowflakeUsername = ref('SF_USER')
    const snowflakePassword = ref(null)
    const snowflakeRole = ref('SF_ROLE')
    const snowflakeWarehouse = ref('COMPUTE_WH')
    const snowflakeEncryptedPassword = ref(null)
    const loadingPasswordCheck = ref(false)
    const activeExpansionPanel = ref(0)
    const encryptedSnowflakePassword = ref(null)
    const loginError = ref(null)
    const isPasswordVisible = ref(false)
    const errorMessages = ref([])
    const passwordVerificationCheck = ref(null)
    const snowflakeAccountVerified = ref(false)

    const writeToAccount = async () => {
      const snowflakeCredentials = {
        username: snowflakeUsername.value,
        password: snowflakeEncryptedPassword.value,
        role: snowflakeRole.value,
        warehouse: snowflakeWarehouse.value,
      }
      await updateDoc({
        collection: 'accounts',
        docId: props.account.id,
        data: { snowflakeCredentials, snowflakeSettings: { creditCost: 3 } },
      })
    }

    const testSnowflakePermissions = async () => {
      let currentTest = null
      try {
        // Test Credentials
        currentTest = 'Authentication'
        await snowflakeQuery('select 1', 'account')

        // Can Use Warehouse
        currentTest = 'Warehouse'
        await snowflakeQuery(`use warehouse ${snowflakeWarehouse.value}`, 'account')

        // Can Access SNOWFLAKE DB Views
        currentTest = 'SnowflakeDB'
        await snowflakeQuery('select count(*) from snowflake.account_usage.roles;', 'account')

        // Test Manage Grants - i.e. show users;
        currentTest = 'ManageGrants'
        await snowflakeQuery('show users', 'account')

        // Test Execute Tasks - i.e. show users;
        currentTest = 'ExecuteTasks'
        let grantsResponse = await snowflakeQuery(`show grants to role "${snowflakeRole.value}"`, 'account')
        // eslint-disable-next-line prefer-destructuring
        grantsResponse = grantsResponse.rows.filter(f => f.privilege === 'EXECUTE TASK')[0]
        if (!grantsResponse) {
          const errorMessage = `${snowflakeRole.value} needs the ability to Resume Tasks requiring the "EXECUTE TASK" privilege. Try running the following in Snowflake:
          grant execute task on account to role "${snowflakeRole.value}";`
          throw errorMessage
        }

        return 'Successful'
      } catch (err) {
        let errorMessage = err

        if (err.includes('User temporarily locked')) {
          errorMessage = `The user account ${snowflakeUsername.value} is locked from too many unsuccessful logins. Try running the following in Snowflake to unlock:
          alter user ${snowflakeUsername.value} set mins_to_unlock = 0;
          `
        }
        if (currentTest === 'Warehouse' && err.includes('Object does not exist')) {
          errorMessage = `The warehouse ${snowflakeWarehouse.value} does not exists or the role ${snowflakeRole.value} does not have access to it. Try running the following in Snowflake:
          grant usage on warehouse "${snowflakeWarehouse.value}" to role "${snowflakeRole.value}";
          `
        }
        if (err.includes("Database 'SNOWFLAKE' does not exist")) {
          errorMessage = `Grants are missing for the SNOWFLAKE ACCOUNT DB. Please run the following command in Snowflake and try again:
          grant imported privileges on database snowflake to role "${snowflakeRole.value}";
          `
        }
        if (currentTest === 'ManageGrants' && err.includes('Insufficient privileges')) {
          errorMessage = `Cannot execute "SHOW USERS" on Snowflake. It requires the "Manage Grants" privilege. Try running the following in Snowflake:
          grant manage grants on account to role "${snowflakeRole.value}";
          `
        }
        throw errorMessage
      }
    }

    const resetLoginPasswordFields = () => {
      passwordVerificationCheck.value = null
      loginError.value = null
      snowflakeAccountVerified.value = false
    }

    const verifyPasswordCredentials = async () => {
      if (snowflakeUsername.value && snowflakePassword.value) {
        loginError.value = null
        loadingPasswordCheck.value = true
        console.log('Checking Credentials on Snowflake...')

        // Encrypt Password and Set on Local Storage for Testing
        const encryptedPassword = await encryptData(snowflakePassword.value)
        encryptedSnowflakePassword.value = encryptedPassword.data
        const accountProfile = props.account
        accountProfile.snowflakeCredentials = {
          username: snowflakeUsername.value,
          password: encryptedSnowflakePassword.value,
        }
        localStorage.setItem('accountProfile', JSON.stringify(accountProfile))
        try {
          const testResponse = await testSnowflakePermissions()
          if (testResponse === 'Successful') {
            loadingPasswordCheck.value = false
            passwordVerificationCheck.value = 'Success'
            snowflakeEncryptedPassword.value = encryptedSnowflakePassword.value
            snowflakeAccountVerified.value = true
            writeToAccount()
          } else {
            loginError.value = testResponse
            snowflakeAccountVerified.value = mdiFormatLineStyle
            loadingPasswordCheck.value = false
          }
        } catch (err) {
          console.log('Error Reported', err)
          snowflakeAccountVerified.value = false
          loginError.value = err
          loadingPasswordCheck.value = false
        }
      }
    }

    const next = () => {
      emit('next')
    }

    onMounted(() => {
      if (props.account.snowflakeCredentials) {
        const credentials = props.account.snowflakeCredentials
        if (credentials.username) snowflakeUsername.value = credentials.username
        if (credentials.role) snowflakeRole.value = credentials.role
        if (credentials.warehouse) snowflakeWarehouse.value = credentials.warehouse
        if (credentials.username && credentials.password) {
          sectionAlreadyCompleted.value = true
          snowflakeAccountVerified.value = true
        }
      }

      // Generate Random Password
      snowflakePassword.value = generateRandomPassword(20)
    })

    return {
      sectionAlreadyCompleted,
      next,
      snowflakeAccountVerified,
      snowflakeWarehouse,
      snowflakeRole,
      resetLoginPasswordFields,
      passwordVerificationCheck,
      loadingPasswordCheck,
      verifyPasswordCredentials,
      isPasswordVisible,
      snowflakeUsername,
      snowflakePassword,
      loginError,
      props,
      activeExpansionPanel,
      serverConfig,
      required,
      errorMessages,
      icons: {
        mdiAccountCircle,
        mdiKeyChainVariant,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
pre code {
  background-color: #000;
  border: 1px solid #000;
  display: block;
  padding: 20px;
}
</style>
