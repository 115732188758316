import _ from 'lodash'

/* eslint-disable object-curly-newline */
function userConsumption({ timestampName, userExecutions, contractData, globalConsumption }) {
  const executions = []
  userExecutions.forEach(row => {
    const timestamp = row[timestampName]

    // Parse Users
    const userList = Object.keys(row.users)
    userList.forEach(user => {
      const userRecord = { ...row.users[user], user, [timestampName]: timestamp, credits: 0, cost: 0 }
      const warehouses = Object.keys(userRecord.warehouses)
      warehouses.forEach(warehouse => {
        const warehouseData = userRecord.warehouses[warehouse]
        const whConsumption = globalConsumption.filter(f => f[timestampName] === timestamp)[0].warehouses[warehouse]
        warehouseData[3] = warehouseData[2] * whConsumption
        warehouseData[4] = warehouseData[3] * contractData.creditCost
        userRecord.warehouses[warehouse] = warehouseData

        // Increment User Totals
        userRecord.credits += warehouseData[3]
        userRecord.cost += warehouseData[4]
      })
      executions.push(userRecord)
    })
  })

  return executions
}
function sumUserConsumptionData(userConsumptionData) {
  let users = userConsumptionData.map(m => m.user)
  users = [...new Set(users)].sort()
  const groupedUsers = []

  users.forEach(user => {
    const consumptionRecords = userConsumptionData.filter(f => f.user === user)

    // Group Warehouse Data
    const warehouses = consumptionRecords.map(m => m.warehouses)
    const warehouseData = []
    warehouses.forEach(warehouse => {
      Object.keys(warehouse).forEach(wh => {
        warehouseData.push({
          warehouse: wh,
          executions: warehouse[wh][0],
          duration: warehouse[wh][1],
          percentage: warehouse[wh][2],
          credits: warehouse[wh][3],
          cost: warehouse[wh][4],
        })
      })
    })
    const groupedWarehouses = _(warehouseData)
      .groupBy('warehouse')
      .map((m, warehouse) => ({
        [warehouse]: [
          _.sumBy(m, 'executions'),
          _.sumBy(m, 'duration'),
          _.meanBy(m, 'percentage'),
          _.sumBy(m, 'credits'),
          _.sumBy(m, 'cost'),
        ],
      }))
      .value()

    const warehouseObject = {}

    groupedWarehouses.forEach(warehouseGroup => {
      const whName = Object.keys(warehouseGroup)
      warehouseObject[whName] = warehouseGroup[whName]
    })

    // Group User Data
    const userGroup = _(consumptionRecords)
      .groupBy('user')
      .map((m, id) => ({
        id,
        executions: _.sumBy(m, 'executions'),
        duration: _.sumBy(m, 'duration'),
        executionErrors: _.sumBy(m, 'executionErrors'),
        rowsDeleted: _.sumBy(m, 'rowsDeleted'),
        rowsInserted: _.sumBy(m, 'rowsInserted'),
        rowsProduced: _.sumBy(m, 'rowsProduced'),
        rowsUpdated: _.sumBy(m, 'rowsUpdated'),
        cost: _.sumBy(m, 'cost'),
        credits: _.sumBy(m, 'credits'),
        creditsUsedCloudServices: _.sumBy(m, 'creditsUsedCloudServices'),
      }))
      .value()
    groupedUsers.push({ ...userGroup[0], warehouses: warehouseObject })
  })

  return groupedUsers
}
export { sumUserConsumptionData, userConsumption }
