<template>
  <div>
    <component
      :is="resolveLayoutVariant"
      :key="appKey"
      :class="`skin-variant--${appSkinVariant}`"
    >
      <!-- <v-alert
        v-if="accountProfile && accountProfile.accountAlert && accountProfile.accountAlert.enabled"
        :type="accountProfile.accountAlert.type"
        dismissible
      >
        <strong>{{ accountProfile.accountAlert.message }}</strong>
      </v-alert> -->
      <transition
        :name="appRouteTransition"
        mode="out-in"
        appear
      >
        <div>
          <loading-interface v-if="(loadingInitialData && !firstTimeSetup) || showLoadingInterface"></loading-interface>
          <router-view v-if="!loadingInitialData"></router-view>
        </div>
      </transition>
    </component>
  </div>
</template>

<script>
// eslint-disable-next-line lines-around-comment
// :is="loadingInitialData ? 'layout-blank' : resolveLayoutVariant"
/* eslint-disable operator-linebreak */
import { readDoc } from '@/firestore'
import store from '@/store'
import AccountSetup from '@/views/account-setup/AccountSetup.vue'
import LoadingInterface from '@/views/authentication/LoadingInterface.vue'
import LayoutBlank from '@/views/layouts/LayoutBlank.vue'
import LayoutContentVerticalNav from '@/views/layouts/LayoutContentVerticalNav.vue'
import useAppConfig from '@core/@app-config/useAppConfig'
import { useLayout } from '@core/layouts/composable/useLayout'
import { useRouter } from '@core/utils'
// eslint-disable-next-line object-curly-newline
import { computed, onBeforeMount, ref, watch, watchEffect } from '@vue/composition-api'
import moment from 'moment-timezone'
import { auth } from './firebaseApp'

// import store from '@/store'

export default {
  components: {
    AccountSetup,
    LoadingInterface,
    LayoutContentVerticalNav,
    LayoutBlank,
  },
  setup() {
    const { router } = useRouter()
    const { route } = useRouter()
    const appKey = ref(0) // computed(() => store.state.appComponentId) // Used for destroying and recreating app component
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()
    const showLoadingInterface = computed(() => store.state.showLoadingInterface)

    const initializeDataSyncs = async accountProfile => {
      // Dispatch Bookmarks First
      const snowflakeBookmarks = await readDoc({ collection: 'bookmarks', docId: 'snowflakeBookmarks' })
      store.commit('snowflakeData/setSnowflakeBookmarks', snowflakeBookmarks)
      store.dispatch('initializeDataSyncs', { account: accountProfile.value })

      // Check if Query History in Progress
      if (!snowflakeBookmarks.snowflakeQueryHistoryLoaded && snowflakeBookmarks.snowflakeQueryHistoryStarted) {
        const message = 'Query History Loading is in progress... This can take some time.'
        store.dispatch('systemMessages', {
          type: 'info',
          message,
          light: true,
          dismissible: true,
        })
      }
    }
    const accountProfile = computed(() => store.state.accountProfile)
    const user = computed(() => store.state.user)
    const firstTimeSetup = computed(() => false)
    watch(accountProfile, (to, from) => {
      if (from === null && to !== null) {
        if (accountProfile.value.snowflakeCredentials) {
          initializeDataSyncs(accountProfile)
        }
      }
    })

    // Checks user authentication with Google
    onBeforeMount(() => {
      // Set TimeZone Info in LocalStorage - Can be overwritten by user object later
      // If localStorage user is null
      if (typeof localStorage.getItem('user') === 'undefined') localStorage.removeItem('user')
      localStorage.setItem(
        'timeZone',
        JSON.stringify({
          zone: moment.tz(new Date(), moment.tz.guess()).format('z').replace('D', 'S'),
          offset: moment.tz(new Date(), moment.tz.guess()).format('Z'),
          name: moment.tz.guess(),
        }),
      )
      const loggedIn = localStorage.getItem('loggedIn')
      auth.onAuthStateChanged(firebaseAuthUser => {
        store.commit('showLoadingInterface', false)
        if (!firebaseAuthUser) {
          if (loggedIn) {
            store.dispatch('logoutUser')
          }
          if (
            route.value.path !== '/login' &&
            route.value.path !== '/register' &&
            !route.value.path.includes('/account-setup') &&
            !route.value.path.includes('/snowflake-oath') &&
            !route.value.path.includes('/new-account-setup')
          ) {
            router.replace('/login')
          }
        } else {
          // eslint-disable-next-line prefer-destructuring
          const { currentUser } = auth
          const account = currentUser.displayName
          store.commit('setAccountId', account)
          store.dispatch('syncAccountAndUserData', { account, email: currentUser.email, userId: currentUser.uid })
          if (!localStorage.getItem('loggedIn')) {
            if (!localStorage.getItem('loggedInMethod')) {
              localStorage.setItem('loggedInMethod', 'oauth')
            }

            // If statement: stops update (login timestamp) from running before db record is created.
            if (localStorage.getItem('newUser')) {
              localStorage.removeItem('newUser')
              localStorage.setItem('loggedIn', true)
            } else {
              const updateTimestampPayload = { accountId: account, userId: currentUser.uid }
              store.dispatch('users/setUserLoginTimestamp', updateTimestampPayload)
              localStorage.setItem('loggedIn', true)
            }
          }
          if (
            route.value.path === '/login' ||
            route.value.path === '/register' // || route.value.path.includes('/snowflake-oath')
          ) {
            router.replace('/')
          }
        }
      })
    })
    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    // Watching for Account Wide Messages
    watchEffect(() => {
      // If Account Alert Exists - Send To System Messages Queue
      if (
        accountProfile.value &&
        accountProfile.value.accountAlert &&
        accountProfile.value.accountAlert.enabled &&
        user.value &&
        accountProfile.value.accountAlert.activeOnUser[user.value.id]
      ) {
        const { accountAlert } = accountProfile.value
        if (accountAlert.type === undefined) accountAlert.type = 'info'
        if (accountAlert.dismissible === undefined) accountAlert.dismissible = true
        if (accountAlert.light === undefined) accountAlert.light = false

        const payloadMessage = {
          type: accountAlert.type,
          message: `${accountAlert.message}`,
          accountAlert: true,
          dismissible: accountAlert.dismissible,
          light: accountAlert.light,
        }
        if (localStorage.getItem('removedSystemMessage') === payloadMessage.message) {
          localStorage.removeItem('removedSystemMessage')
        } else {
          store.dispatch('systemMessages', payloadMessage)
        }
      }
    })

    const loadingInitialData = computed(() => {
      // Check if User Has Completed Snowflake System Account Setup - If Not Redirect to /account-setup/system-account-setup/
      if (user.value && accountProfile.value && !accountProfile.value.snowflakeCredentials) {
        if (route.value.path !== '/account-setup/system-account-setup/') {
          console.log('No System Snowflake Credentials... Redirecting to Account Setup...')
          router.replace('/account-setup/system-account-setup/')
        }
      } else if (user.value && !user.value.snowflakeCredentials) {
        // Check if User Has Completed Snowflake Account Setup - If Not Redirect to /user-setup/
        if (route.value.path !== '/account-setup/user-setup/') {
          console.log('No Snowflake Credentials... Redirecting to User Setup...')
          router.replace('/account-setup/user-setup/')
        }
      }

      // The Following Array MUST Return Before the UI is Shown
      const consumption = store.state.snowflakeData.snowflakeConsumptionDaily.length > 0
      const executions = store.state.snowflakeData.snowflakeExecutionsDaily.length > 0
      const users = store.state.snowflakeData.snowflakeUsers.length > 0
      const schema = store.state.snowflakeData.snowflakeTableFreshness.length > 0
      const storage = store.state.snowflakeData.snowflakeStorage.length > 0

      if (consumption && executions && users && schema && storage) {
        return false
      }
      if (
        route.value.path === '/login' ||
        route.value.path === '/register' ||
        route.value.path.includes('/account-setup') ||
        route.value.path.includes('/snowflake-oath')
      ) {
        return false
      }

      return true // Should be true when not testing
    })

    const resolveLayoutVariant = computed(() => {
      if (accountProfile.value && user.value && route.value.meta.layout === 'content') {
        return `layout-content-${appContentLayoutNav.value}-nav`
      }
      if (route.value.meta.layout === 'blank') {
        return 'layout-blank'
      }

      return null
    })

    return {
      appKey,
      showLoadingInterface,
      firstTimeSetup,
      accountProfile,
      loadingInitialData,
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },
}
</script>
