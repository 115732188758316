<template>
  <div>
    <v-card
      outlined
      class="mb-8 pa-5"
    >
      <v-row
        justify="center"
      >
        <v-col sm="12">
          <v-alert
            type="success"
            text
          >
            Snowflake System Account Verified
          </v-alert>
        </v-col>
        <v-col sm="12">
          <v-alert
            color="primary"
            dark
            :icon="icons.mdiCog"
          >
            <strong>Below are some other system settings to configure...</strong>
          </v-alert>
        </v-col>
        <v-col
          sm="12"
          md="6"
        >
          <help-badge @clickAll="helpFreshnessChecks = true">
            <v-alert
              border="left"
              color="primary"
              dark
              text
            >
              <strong>How often would you like to run data freshness, schema, and other checks?</strong>
            </v-alert>
          </help-badge>
          <help-dialog
            :open="helpFreshnessChecks"
            @close="helpFreshnessChecks = false"
          >
            We can run background data freshness checks for you automatically to alert you to freshness issues, data anomalies, etc. *Note: more frequent checks can cause an increase in Snowflake consumption costs.
          </help-dialog>
        </v-col>
        <v-col

          sm="12"
          md="6"
        >
          <div v-if="false">
            <help-badge @clickAll="helpHistoricalFreshness = true">
              <v-alert
                border="left"
                color="primary"
                dark
                text
              >
                <strong>Get Historical Freshness Data? Requires 90 Day Snowflake Time Travel capabilities.</strong>
              </v-alert>
            </help-badge>
            <help-dialog
              :open="helpHistoricalFreshness"
              @close="helpHistoricalFreshness = false"
            >
              We can use Snowflake's Time Travel capabilities to go back in time to build a historical freshness view of your data. Would you like to do so, and how many days back? Note: This feature requires Snowflake accounts with 90 day Time Travel Capabilities.  *Note: This process can take some time and affect consumption costs depending on the amount of tables needed to query.
            </help-dialog>
          </div>
        </v-col>
        <v-col
          sm="12"
          md="6"
        >
          <v-select
            v-model="freshnessInterval"
            outlined
            label="Data Refresh Interval"
            :items="freshnessIntervalItems"
          ></v-select>
        </v-col>
        <v-col
          sm="12"
          md="6"
        >
          <v-text-field
            v-if="false"
            v-model="historicalFreshness"
            outlined
            label="Days Back"
          ></v-text-field>
        </v-col>
        <v-col sm="12">
          <v-alert
            border="left"
            color="primary"
            dark
            text
          >
            <strong>We can track Snowflake Consumption costs for you, either by On-Demand credit pricing or contract pricing.</strong>
          </v-alert>
        </v-col>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="primary--text"><strong>On-Demand Credit Pricing</strong></span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col
                  sm="12"
                  md="6"
                >
                  <v-text-field
                    v-model="creditCost"
                    label="$ per Credit"
                    outlined
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="primary--text"><strong>Contract Pricing</strong></span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col
                  sm="12"
                  md="6"
                >
                  <v-text-field
                    v-model="contractAllowedCredits"
                    label="# of Credits Allowed in Contract"
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  sm="12"
                  md="6"
                >
                  <v-text-field
                    v-model="contractAmount"
                    label="Total Contract Amount ($)"
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <template>
                  <v-row>
                    <v-col
                      sm="12"
                      md="6"
                    >
                      <v-menu
                        v-model="contractStartDialog"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="contractStart"
                            label="Contract Start Date"
                            :prepend-icon="icons.mdiCalendar"
                            readonly
                            v-bind="attrs"
                            outlined
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="contractStart"
                          color="primary"
                          @input="contractStartDialog = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col
                      sm="12"
                      md="6"
                    >
                      <v-menu
                        v-model="contractEndDialog"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="contractEnd"
                            label="Contract End Date"
                            :prepend-icon="icons.mdiCalendar"
                            readonly
                            v-bind="attrs"
                            outlined
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="contractEnd"
                          color="primary"
                          @input="contractEndDialog = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </template>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-card>
    <div class="d-flex justify-space-between">
      <v-btn
        outlined
        @click="previous"
      >
        Previous
      </v-btn>
      <v-btn
        :loading="loading"
        color="primary"
        @click="next"
      >
        Next
      </v-btn>
    </div>
  </div>
</template>

<script>
import { updateDoc } from '@/firestore'
import { mdiCalendar, mdiCloseCircle, mdiCog } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import moment from 'moment-timezone'

export default {
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const creditCost = ref(3)
    const contractAllowedCredits = ref(null)
    const contractAmount = ref(null)
    const contractStart = ref(null)
    const contractEnd = ref(null)
    const contractStartDialog = ref(false)
    const contractEndDialog = ref(false)
    const loading = ref(false)
    const helpFreshnessChecks = ref(false)
    const helpHistoricalFreshness = ref(false)
    const historicalFreshness = ref(0)
    const freshnessInterval = ref(60)
    const freshnessIntervalItems = [
      { text: 'Every 5 Minutes', value: 5 },
      { text: 'Every 10 Minutes', value: 10 },
      { text: 'Every 30 Minutes', value: 30 },
      { text: 'Every Hour', value: 60 },
      { text: 'Every 3 Hours', value: 180 },
      { text: 'Every 6 Hours', value: 360 },
      { text: 'Every 24 Hours', value: 1440 },
    ]

    onMounted(() => {
      console.log('Account', props.account.snowflakeSettings)
      if (props.account.snowflakeSettings) {
        const settings = props.account.snowflakeSettings
        if (settings.contractAllowedCredits) contractAllowedCredits.value = settings.contractAllowedCredits
        if (settings.contractAmount) contractAmount.value = settings.contractAmount

        if (settings.contractEnd) {
          // eslint-disable-next-line prefer-destructuring
          contractEnd.value = new Date(settings.contractEnd.seconds * 1000).toISOString().split('T')[0]
        }
        if (settings.contractStart) {
          // eslint-disable-next-line prefer-destructuring
          contractStart.value = new Date(settings.contractStart.seconds * 1000).toISOString().split('T')[0]
        }
        if (settings.creditCost) creditCost.value = settings.creditCost
        if (settings.historicalFreshnessDays) historicalFreshness.value = settings.historicalFreshnessDays
        if (settings.refreshInterval) freshnessInterval.value = settings.refreshInterval
      }
      if (props.account.jobs) {
        const { jobs } = props.account
        if (jobs.snowflakeSchemaRefreshMinutes) freshnessInterval.value = jobs.snowflakeSchemaRefreshMinutes
      }
    })

    const updateSnowflakeSettings = async () => {
      // Update Settings
      let settings = props.account.snowflakeSettings

      let payload = { historicalFreshnessDays: historicalFreshness.value }
      if (contractAllowedCredits.value && contractStart.value && contractEnd.value) {
        payload = {
          ...payload,
          contractCredits: contractAllowedCredits.value,
          contractAmount: contractAmount.value,
          contractStart: moment(contractStart.value).tz(moment.tz.guess()).toDate(),
          contractEnd: moment(contractEnd.value).tz(moment.tz.guess()).toDate(),
        }
      } else {
        payload = { ...payload, creditCost: creditCost.value }
      }
      settings = {
        ...settings,
        ...payload,
      }
      await updateDoc({ collection: 'accounts', docId: props.account.id, data: { snowflakeSettings: settings } })

      // Update Refresh Frequency
      const jobs = props?.account?.jobs || {}
      jobs.snowflakeSchemaRefreshMinutes = freshnessInterval.value
      jobs.snowflakeQueryHistoryRefreshMinutes = freshnessInterval.value
      jobs.snowflakeExecutionRefreshMinutes = freshnessInterval.value
      jobs.snowflakeTasksRefreshMinutes = freshnessInterval.value
      await updateDoc({ collection: 'accounts', docId: props.account.id, data: { jobs } })
    }

    const previous = () => {
      emit('previous')
    }

    const next = async () => {
      loading.value = true
      await updateSnowflakeSettings()
      loading.value = false
      emit('next')
    }

    return {
      previous,
      next,
      historicalFreshness,
      freshnessInterval,
      freshnessIntervalItems,
      helpHistoricalFreshness,
      helpFreshnessChecks,
      loading,
      creditCost,
      contractAllowedCredits,
      contractAmount,
      contractStart,
      contractEnd,
      contractStartDialog,
      contractEndDialog,
      icons: {
        mdiCalendar,
        mdiCog,
        mdiCloseCircle,
      },
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep .v-snack__wrapper {
  border: thick solid;
  background-color: #fff;
}
</style>
