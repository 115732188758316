const environment = 'prod' // local or prod

const serverConfig = {
  name: 'SnowFlow',
  logo: require('@/assets/images/logos/SnowFlowLogo_Small.png'),
  horizontalLogo: require('@/assets/images/logos/SnowFlowLogoHorizontal_Small.png'),
  bannerLogo: require('@/assets/images/logos/SnowFlowLogoSquareWithSubHeader_Medium.png'),
  environment,
  functionEnvironment: environment === 'prod' ? 'prod' : 'local', // local or prod
  baseUrl: environment === 'prod' ? 'https://console.snowflow.app' : 'http://localhost:8080',
  snowflakeOauthRedirectURI: environment === 'prod' ? 'https://console.snowflow.app/snowflake-oath' : 'http://localhost:8080/snowflake-oath',
  localOauthClientId: 'E2w+2BSAf7i+xQyR6k2t/BT3OiY=',
  localOauthClientSecret: 'YVuCDpqCPPFXufjoEMR6Ds9TAZ9Kt/xbLcEZSR64TZk=',
}

export default serverConfig
