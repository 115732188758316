const sampleUsersData = [
  'Percy Langosh',
  'Larissa Kihn',
  'David Adams',
  'Rodrick Marquardt',
  'Perry Auer',
  'Elza Quitzon',
  'Ada Howell PhD',
  'Connie Fritsch',
  'Matilda Zboncak',
  'Hazel Bahringer',
  'Kariane Rowe PhD',
  'Francisco Kulas',
  'Cassie Bosco',
  'Edmond Effertz',
  'Blanca Beahan',
  'Makayla Wolff',
  'Cecil Witting',
  'Ova Rowe',
  'Ivah Crona',
  'Janiya Walker',
  'Henry Schinner',
  'Kenyatta Nikolaus',
  'Ruthie Connelly',
  'Jace Waelchi',
  'Rylee Wehner',
  'Sonny Grimes',
  'Drake Davis',
  'Cassandra Hegmann',
  'Winston Cruickshank',
  'Hallie Kiehn',
  'Chesley Mertz',
  'Sandrine Schulist',
  'Chauncey Smith',
  'Green Hyatt',
  'Renee Mitchell',
  'Casimir Moore',
  'Raymundo Schinner',
  'Deontae Kautzer',
  'Dalton Bradtke',
  'Rosalia Ziemann',
  'Keira Hickle',
  'Miss Alene Gleason',
  'Rahsaan Friesen',
  'Ashley Dibbert',
  'Jayda Schaefer',
  'Braxton Ankunding',
  'Brigitte Lesch',
  'Melvina Padberg',
  'Nakia Borer',
  'Deion Bosco',
  'Myah Heller',
  'Reanna Beier',
  'Clark Christiansen',
  'Eloy Pacocha',
  'Haylie Cartwright',
  'Pattie Boehm',
  'Rupert Wiza',
  'Muhammad Spencer',
  'Ciara Morar',
  'Shayne Shanahan',
  'Sylvester Borer',
  'Clementina Hermann',
  'Deshaun Collins',
  'Miss Salvatore Hilll',
  'Elvie Schuppe',
  'Kenton Blick',
  'Fredy Schulist',
  'Dovie Bradtke',
  'Abner Nolan',
  'Herminio Russel',
  'Ambrose Cartwright',
  'Akeem Lubowitz',
  'Aurelio McClure',
  'Lyla Batz',
  'Jodie Beatty',
  'Rhiannon Gleason',
  'Mireya Stiedemann',
  'Gerda Franecki',
  'Lauryn Frami',
  'Amanda Stehr',
  'Elmo Runolfsson',
  'Dagmar Rempel',
  'Marcelo Kassulke',
  'Hardy Schamberger',
  'Sonya Wolf',
  'Flossie Brown',
  'Minerva Turner',
  'Antwon Keeling',
  'Colt Schaden',
  'Adriel Ward',
  'Fredy Bode',
  'Kaelyn Sporer',
  'Jaydon Bruen',
  'Katherine Kuhlman',
  'Jasper Zulauf',
  'Vilma Douglas',
  'Lester Jakubowski',
  'Eliza Gorczany',
  'Hanna Labadie',
  'Julien Kuphal',
  'Georgianna Stracke V',
  'Miss Jarrett Oberbrunner',
  'Sean Keefe',
  'Alfred Morar',
  'Rosina Kling',
  'Vallie Monahan',
  'Sarah Kerluke',
  'Ettie Koch',
  'Reece Gerlach',
  'Althea Metz V',
  'Kevin Hudson',
  'Buford Kirlin',
  'Miss Frederik Robel',
  'Maxine Olson',
  'Bettye Emmerich',
  'Sofia Hansen',
  'Wilber Toy',
  'Sonya Bogan',
  'Carmine Gerhold',
  'Leland Lesch',
  'Ethan Sawayn',
  'Gerard Lebsack',
  'Yesenia Nitzsche',
  'Chanelle Jacobs',
  'Winnifred Bechtelar',
  'Laurel Kessler',
  'Jakayla Reichert',
  'Julian Legros',
  'Tiara Little',
  'Aron Hayes',
  'Arne Hand',
  'Rolando Crist',
  'Brain Wolf',
  'Terrence Bosco',
  'Oscar Greenfelder',
  'Bonnie Hoppe',
  'Skylar Wunsch',
  'Myron Hagenes',
  'Deion Dibbert',
  'Thora Sipes',
  'Myriam Wilkinson Jr',
  'Carol Labadie',
  'Mireille Schiller',
  'Forrest Kerluke',
  'Era Miller',
  'Meaghan Waelchi',
  'Bobbie Stiedemann',
  'Norma Dare',
  'Doris Schowalter',
  'Price Franecki',
  'Tomas Durgan',
  'Megane Guann',
  'Tyler Dietrich Jr',
  'Margarete Williamson',
  'Eveline Jakubowski',
  'Petra Dibbert Sr',
  'Kieran Smitham',
  'Sally Steuber PhD',
  'Reba Weber',
  'Monserrate Runte',
  'Stefanie Gottlieb',
  'Mariah King',
  'Elisa Jacobi',
  'Darian Bednar',
  'Jalyn Gutkowski',
  'Conner Greenholt',
  'Marilie Crist',
  'Annette Rice',
  'Keely Crooks',
  'Dylan Barton',
  'Gerald Terry',
  'Abner Parker',
  'Chauncey Amore',
  'Aryanna Bailey',
  'Caroline Ward PhD',
  'Verlie Leannon Jr',
  'Meda Nitzsche',
  'Horacio Flatley DVM',
  'Brittany Ferry',
  'Alexanne Runolfsson',
  'Mina Bashirian',
  'Wiley Keebler',
  'Lolita Prosacco',
  'Lorenz Goyette',
  'Jarvis White',
  'Lafayette Gusikowski',
  'Keeley Kon',
  'Bud Morissette',
  'Sterling Sipes',
  'Maxie Rau',
  'Hadley Satterfield',
  'Clay McDermott Sr',
  'Trevion Greenholt',
  'Grover Price',
  'Garnett Wiza',
  'Lucas Bayer',
  'Maria West',
  'Shelley Richardson',
  'Thomas Evans',
  'Jeffrey Gordon',
  'Kenneth Smith',
  'John Williams',
  'Michelle Mills',
  'Rachael Hammond',
  'Robert Hill',
  'Glenn Garcia',
  'Bridget Torres',
  'Jose Harris',
  'Jonathan Mckinney',
  'Natalie Sampson',
  'Miguel Kim',
  'Dylan Smith',
  'Steven Smith',
  'Kevin Mcdonald',
  'Jill Smith',
  'Nicholas Burke',
  'Steven Gregory',
  'Edward Horn',
  'Victor Burton',
  'Robert Rodriguez',
  'Derek Lewis',
  'Sarah Grant',
  'Tammy Anderson',
  'Eric Jimenez',
  'Michael Garrison',
  'Kayla Vasquez',
  'Cameron Hernandez',
  'Meghan Cowan',
  'Jennifer Ortiz',
  'Patricia Burns',
  'Tammy Ortiz',
  'Anita Nicholson',
  'Jasmine Ward',
  'Keith Lloyd',
  'Michael Russell',
  'Melissa Jackson',
  'Sandra Hanson',
  'Robert Cline',
  'Laura Reid',
  'Troy Johnson',
  'Antonio Walker',
  'Christopher Russell',
  'Joan Diaz',
  'Pamela Rodriguez',
  'Gary Johnson',
  'Aaron Mcdowell',
  'Gabriel Dixon',
  'Katherine Martin',
  'William Olson',
  'Phillip Carter',
  'William Sanders',
  'Heather Griffin',
  'Denise Reed',
  'Daniel Mills',
  'Maria Sweeney',
  'Julie Moss',
  'Henry White',
  'William Ortega',
  'Crystal Smith',
  'Michael Chan',
  'Michael Cannon',
  'Brent Garcia',
  'Angela Schmidt',
  'Kevin Brown',
  'Andrew Dickson',
  'Miss Katrina Lee',
  'Ethan Davis',
  'Kathryn Collins',
  'Jennifer Wright',
  'John Lee',
  'Christian Jones',
  'Andrew Torres',
  'Michelle Mcintosh',
  'Kelsey Russell',
  'Christopher Ayers',
  'Holly Delacruz',
  'Vanessa Vance',
  'Emily Warner',
  'Michael Robertson',
  'Amy Hoover',
  'Christopher Montgomery',
  'Derrick Campbell',
  'Karen Wood',
  'Thomas Barber',
  'John Vargas',
  'Jonathan Mcgee',
  'Katherine Peck',
  'Anne Franklin',
  'Christina Foster',
  'Jill Berger',
  'Tiffany Russell',
  'James Marquez',
  'Juan Anthony',
  'Terry Braun',
  'Mary Campbell',
  'Patricia Duarte',
]

const sampleRolesData = {
  ACCOUNTADMIN: 'ACCOUNTADMIN',
  DOMO_ROLE: 'DASHBOARDING_ROLE',
  METABASE_ROLE: 'DASHBOARDING_ROLE',
  BI_TEAM_ROLE: 'ETL_ROLE',
  EXTERNAL_SPIFF_ROLE: 'EXTERNAL_ROLE',
  FIVETRAN_ROLE: 'INGESTION_ROLE',
  HEVO_ROLE: 'INGESTION_ROLE',
  LENDIO_ML_ROLE: 'ML_ROLE',
  PUBLIC: 'PUBLIC',
  SECURITYADMIN: 'SECURITYADMIN',
  SNOWFLAKE_ACCOUNT: 'SNOWFLAKE_ACCOUNT',
  SF_ROLE: 'SF_ROLE',
  SYSADMIN: 'SYSADMIN',
  USERADMIN: 'USERADMIN',
}

const specialtyNames = {
  HEVO: 'INGESTION_SERVICE_ONE',
  FIVETRAN_USER: 'INGESTION_SERVICE_TWO',
  DOMO_USER: 'VIZ_SERVICE',
  BI_USER: 'ETL_SERVICE',
  METABASE_USER: 'VIZ_SERVICE_2',
  SAM_USER: 'AWS_SERVICE',
  SF_USER: 'SNOWFLOW USER',
}

function roleMapping(role) {
  if (Object.keys(sampleRolesData).includes(role)) {
    return sampleRolesData[role]
  }

  return 'PRODUCTION_ROLE'
}

function sampleUsers(userData) {
  const users = [...userData]
  users.forEach((user, i) => {
    if (Object.keys(specialtyNames).includes(user.id)) {
      users[i].loginName = specialtyNames[user.id]
      users[i].displayName = specialtyNames[user.id]
      users[i].firstName = specialtyNames[user.id]
      users[i].lastName = ''
      users[i].name = specialtyNames[user.id]
    } else {
      const firstName = sampleUsersData[i].split(' ')[0]
      const lastName = sampleUsersData[i].split(' ')[1]
      if (users[i]) {
        users[i].loginName = `${firstName}.${lastName}`
        users[i].displayName = `${firstName} ${lastName}`
        users[i].firstName = firstName
        users[i].lastName = lastName
        users[i].name = `${firstName}.${lastName}`
      }
    }

    // Set Default Role
    const newRole = roleMapping(user.defaultRole)
    users[i].defaultRole = newRole
  })
  console.log(users.filter(f => f.id === 'HEVO'))

  return users
}

function sampleRoles(rolesData) {
  const roles = [...rolesData]
  const newRoles = []
  roles.forEach((role, i) => {
    const newRole = roleMapping(role.name)
    const validate = newRoles.map(m => m.name)
    if (!validate.includes(newRole)) {
      newRoles.push({
        ...roles[i],
        name: newRole,
        comment: `This is the ${newRole} uses in the database.`,
        owner: 'ACCOUNTADMIN',
      })
    }
  })

  return newRoles
}

function sampleUserRoles(userRolesData) {
  const userRoles = [...userRolesData]
  userRoles.forEach((user, userIndex) => {
    const newRoles = []
    user.roles.forEach(role => {
      newRoles.push(roleMapping(role))
    })
    userRoles[userIndex].roles = newRoles
  })

  return userRoles
}

export { sampleUsers, sampleRoles, sampleUserRoles }
