/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
import {
  addDoc,
  collection,
  collectionGroup,
  deleteDoc as deleteDocument,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc as updateDocument,
  where,
} from 'firebase/firestore'
import { auth, db } from '../firebaseApp'

// Most Firestore Collections Are Structured as the Following:
// {Collection (tasks)} -> 'accounts' -> {accountId (1)} -> {docId}

// Read SINGLE DOC from Firebase
// Payload: { collection, docId, subCollection[optional], subCollectionDocId[optional] }
async function readDoc(payload) {
  if (!payload.collection || !payload.docId) {
    const message =
      "Firestore Error: You must call readDoc() with the payload: { collection: 'collectionName', docId: 'string', subCollection[optional], subCollectionDocId[optional] }"
    throw message
  }
  let account = null
  if (payload.account?.id) {
    account = payload.account.id
  } else if (localStorage.getItem('accountId')) {
    account = localStorage.getItem('accountId')
  } else if (auth?.currentUser?.displayName) {
    account = auth.currentUser.displayName
  } else {
    const noAccountError = 'No Account Specified'
    throw noAccountError
  }
  let collectionRef = `${payload.collection}/accounts/${account}`
  collectionRef = payload.subCollection ? `${collectionRef}/${payload.docId}/${payload.subCollection}` : collectionRef
  const docId = payload.subCollection ? payload.subCollectionDocId.toString() : payload.docId.toString()
  if (payload.collection === 'accounts') {
    collectionRef = payload.collection
  }
  try {
    const docSnap = await getDoc(doc(db, collectionRef, docId))
    if (docSnap.exists()) {
      return docSnap.data()
    }
    console.log('No such document!')
  } catch (err) {
    const errorMessage = `Firestore Error while trying to readDoc: ${err}`
    console.log(errorMessage)
    throw errorMessage
  }

  return null
}

// Read MULTIPLE DOCS from Firebase
// Payload: { collection: 'tasks', where[optional]: [['name', '==', 'test']], orderBy[optional]: [['name', 'desc']], limit: 10 }
// Where should be wrapped in Arrays (key, operator, value) in another array for multiple results
async function readDocs(payload) {
  let account = null
  if (payload.account?.id) {
    account = payload.account.id
  } else if (localStorage.getItem('accountId')) {
    account = localStorage.getItem('accountId')
  } else if (auth?.currentUser?.displayName) {
    account = auth.currentUser.displayName
  } else {
    const noAccountError = 'No Account Specified'
    throw noAccountError
  }
  let collectionRef = `${payload.collection}/accounts/${account}`
  if (payload.collection === 'accounts') {
    collectionRef = payload.collection
  }

  let queryStatement = query(collection(db, collectionRef))
  if (payload.where) {
    payload.where.forEach(condition => {
      queryStatement = query(queryStatement, where(condition[0], condition[1], condition[2]))
    })
  }
  if (payload.orderBy) {
    payload.orderBy.forEach(condition => {
      queryStatement = query(queryStatement, orderBy(condition[0], condition[1]))
    })
  }
  if (payload.limit) {
    queryStatement = query(queryStatement, limit(payload.limit))
  }

  // const q = query(collection(db, collRef), where('name', '==', 'test'))
  const querySnapshot = await getDocs(queryStatement)
  const results = []
  querySnapshot.forEach(eachDoc => {
    results.push({ ...eachDoc.data(), docId: eachDoc.id })
  })

  return results
}

// Read ENTIRE COLLECTIONS from Firebase
// WILL ALWAYS Look for "accountId" on the collection to filter to the right account
// Payload: { collection: 'tasks', where[optional]: [['name', '==', 'test']], orderBy[optional]: [['name', 'desc']], limit: 10 }
// Where should be wrapped in Arrays (key, operator, value) in another array for multiple results
async function readCollection(payload) {
  let account = null
  if (payload.account?.id) {
    account = payload.account.id
  } else if (localStorage.getItem('accountId')) {
    account = localStorage.getItem('accountId')
  } else if (auth?.currentUser?.displayName) {
    account = auth.currentUser.displayName
  } else {
    const noAccountError = 'No Account Specified'
    throw noAccountError
  }
  const collectionRef = payload.collection
  let queryStatement = query(collectionGroup(db, collectionRef))

  // Add Required Account Data
  queryStatement = query(queryStatement, where('accountId', '==', account))
  if (payload.where) {
    payload.where.forEach(condition => {
      queryStatement = query(queryStatement, where(condition[0], condition[1], condition[2]))
    })
  }
  if (payload.orderBy) {
    payload.orderBy.forEach(condition => {
      queryStatement = query(queryStatement, orderBy(condition[0], condition[1]))
    })
  }
  if (payload.limit) {
    queryStatement = query(queryStatement, limit(payload.limit))
  }

  const querySnapshot = await getDocs(queryStatement)
  const results = []
  querySnapshot.forEach(eachDoc => {
    results.push({ ...eachDoc.data(), docId: eachDoc.id })
  })

  return results
}

async function writeDoc(payload) {
  let accountId = null
  if (payload.accountId) {
    accountId = payload.accountId
  } else if (payload.account?.id) {
    accountId = payload.account.id
  } else if (localStorage.getItem('accountId')) {
    accountId = localStorage.getItem('accountId')
  } else if (auth?.currentUser?.displayName) {
    accountId = auth.currentUser.displayName
  } else {
    const noAccountError = 'No Account Specified'
    throw noAccountError
  }
  try {
    let collectionRef =
      payload.collection === 'accounts' ? payload.collection : `${payload.collection}/accounts/${accountId}`
    let docId = payload.subCollection ? payload.subCollectionDocId : payload.docId
    if (docId) {
      collectionRef = payload.subCollection
        ? `${collectionRef}/${payload.docId}/${payload.subCollection}`
        : collectionRef
      docId = docId.toString()
      await setDoc(doc(db, collectionRef, docId), payload.data) // Forces a Custom DocId
      console.log(`${docId} written to DB`)

      return `Wrote document on ${collectionRef} | docId:  ${docId}`
    }
    collectionRef = payload.subCollection ? `${collectionRef}/${payload.docId}/${payload.subCollection}` : collectionRef
    const docRef = await addDoc(collection(db, collectionRef), payload.data) // Uses Generated DocId
    console.log('Wrote Document: ', docRef.id)

    return `Wrote document at ${collectionRef} | docId: ${docRef}`
  } catch (err) {
    const errorMessage = `There was an error trying to write to Firebase: ${err}`
    console.log({ error: errorMessage })
    throw errorMessage
  }
}

// Update an Existing Firebase Document without full doc overwrite
// Payload: { collection: 'users', docId: 'XXXXXXXXXX', data: { name: 'Tyler', email: ...' } }
async function updateDoc(payload) {
  if (!payload.collection || !payload.docId || !payload.data) {
    const message =
      "Firestore Error: You must call updateDoc() with the payload: { collection: 'users', docId: 'XXXXXXXXXX', data: { name: 'Tyler', email: ...' } }"
    throw message
  }
  let account = null
  if (payload.account?.id) {
    account = payload.account.id
  } else if (localStorage.getItem('accountId')) {
    account = localStorage.getItem('accountId')
  } else if (auth?.currentUser?.displayName) {
    account = auth.currentUser.displayName
  } else {
    const noAccountError = 'No Account Specified'
    throw noAccountError
  }
  try {
    let collectionRef = `${payload.collection}/accounts/${account}`
    if (payload.collection === 'accounts') {
      collectionRef = payload.collection
    }
    await updateDocument(doc(db, collectionRef, payload.docId.toString()), payload.data) // updateDocument being called as Alias of "updateDoc"

    return `Made Updates to DB: ${payload.collection} on ${payload.docId.toString()}`
  } catch (err) {
    console.log('There was an error trying to write to Firebase: ', err)

    return err
  }
}

async function deleteDoc(payload) {
  if (!payload.collection || !payload.docId) {
    const message =
      "Firestore Error: You must call deleteDoc() with the payload: { collection: 'collectionName', docId: 'string', subCollection[optional], subCollectionDocId[optional] }"
    throw message
  }
  let account = null
  if (payload.account?.id) {
    account = payload.account.id
  } else if (localStorage.getItem('accountId')) {
    account = localStorage.getItem('accountId')
  } else if (auth?.currentUser?.displayName) {
    account = auth.currentUser.displayName
  } else {
    const noAccountError = 'No Account Specified'
    throw noAccountError
  }
  let collectionRef = `${payload.collection}/accounts/${account}`
  collectionRef = payload.subCollection ? `${collectionRef}/${payload.docId}/${payload.subCollection}` : collectionRef
  const docId = payload.subCollection ? payload.subCollectionDocId.toString() : payload.docId.toString()
  if (payload.collection === 'accounts') {
    collectionRef = payload.collection
  }
  try {
    await deleteDocument(doc(db, collectionRef, docId))

    return 'Success'
  } catch (err) {
    const errorMessage = `Firestore Error while trying to deleteDoc: ${err}`
    console.log(errorMessage)
    throw errorMessage
  }
}

async function firestoreTimestamps(rows) {
  const dataSet = rows
  dataSet.forEach((row, i) => {
    Object.keys(row).forEach(key => {
      if (dataSet[i][key] && dataSet[i][key].seconds) {
        dataSet[i][key] = new Date(dataSet[i][key].seconds * 1000)
      }
    })
  })

  return dataSet
}

async function updateSnowflakeBookmarks({ name, value, zone }) {
  try {
    let data = {}
    data[name] = value
    if (zone) {
      data = {}
      data[`${zone}.${name}`] = value
    }
    console.log({ collection: 'bookmarks', docId: 'snowflakeBookmarks', data })
    await updateDoc({ collection: 'bookmarks', docId: 'snowflakeBookmarks', data })
  } catch (err) {
    const errorMessage = `Couldn't update Bookmark for ${name} to ${value}: ${err}`
    console.log(errorMessage)
    throw errorMessage
  }
}

// Document Syncs Cannot be Used Here But Here is the Flow:

// Single Doc

// import { doc, onSnapshot } from "firebase/firestore";

// const unsub = onSnapshot(doc(db, "cities", "SF"), (doc) => {
//     console.log("Current data: ", doc.data());
// });

// Multiple Docs

// import { collection, query, where, onSnapshot } from "firebase/firestore";

// const q = query(collection(db, "cities"), where("state", "==", "CA"));
// const unsubscribe = onSnapshot(q, (querySnapshot) => {
//   const cities = [];
//   querySnapshot.forEach((doc) => {
//       cities.push(doc.data().name);
//   });
//   console.log("Current cities in CA: ", cities.join(", "));
// });

export {
  readDoc,
  readDocs,
  readCollection,
  writeDoc,
  updateDoc,
  deleteDoc,
  firestoreTimestamps,
  updateSnowflakeBookmarks,
}
