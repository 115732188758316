/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable object-curly-newline */
import { db } from '@/firebaseApp'
import { firestoreTimestamps } from '@/firestore'
import { distributionHighMediumLow, formatNumbers } from '@/functions'
import { convertKeys } from '@/functions/stringCaseConversions'
import { snowflakeQuery } from '@/snowflake'
import { collection, doc, onSnapshot, query, where } from 'firebase/firestore'
import _ from 'lodash'
import moment from 'moment-timezone'
import { sampleRoles, sampleUserRoles } from '../sampleData'
import { sumUserConsumptionData, userConsumption } from './snowflakeData_Functions'

export default {
  namespaced: true,
  state: {
    snowflakeDataDaysBack: 7,
    snowflakeBookmarks: [],
    snowflakeUsers: [],
    snowflakeWarehouses: [],
    snowflakeRoles: [],
    snowflakeUserRoles: [],
    snowflakeTasks: [],
    snowflakeExecutionsDaily: [],
    snowflakeExecutionsMonthly: [],
    snowflakeConsumptionDaily: [],
    snowflakeConsumptionMonthly: [],
    snowflakeStorage: [],
    snowflakeTableFreshness: [],
    snowflakeContractDetails: {},
  },

  mutations: {
    setSnowflakeBookmarks(state, payload) {
      state.snowflakeBookmarks = payload
    },
    setSnowflakeUsers(state, payload) {
      state.snowflakeUsers = payload
    },
    setSnowflakeWarehouses(state, payload) {
      state.snowflakeWarehouses = payload
    },
    setSnowflakeRoles(state, payload) {
      state.snowflakeRoles = payload
    },
    setSnowflakeUserRoles(state, payload) {
      state.snowflakeUserRoles = payload
    },
    addSnowflakeUserRoles(state, payload) {
      // Pass { granteeName, role }
      const { granteeName, role } = payload
      state.snowflakeUserRoles.push({ createdOn: new Date(), grantedBy: '', grantedTo: 'USER', granteeName, role })
    },
    setSnowflakeTasks(state, payload) {
      state.snowflakeTasks = payload
    },
    setSnowflakeConsumptionDaily(state, payload) {
      state.snowflakeConsumptionDaily = payload
    },
    setSnowflakeConsumptionMonthly(state, payload) {
      state.snowflakeConsumptionMonthly = payload
    },
    setSnowflakeStorage(state, payload) {
      state.snowflakeStorage = payload
    },
    setSnowflakeExecutionsDaily(state, payload) {
      state.snowflakeExecutionsDaily = payload
    },
    setSnowflakeExecutionsMonthly(state, payload) {
      state.snowflakeExecutionsMonthly = payload
    },
    setSnowflakeTableFreshness(state, payload) {
      state.snowflakeTableFreshness = payload
    },
    setSnowflakeContractDetails(state, payload) {
      state.snowflakeContractDetails = payload
    },
  },
  actions: {
    // Snowflake Users
    // /////////////////////
    async refreshSnowflakeUsers({ commit }) {
      let snowflakeUsers = await snowflakeQuery('show users', 'account')
      snowflakeUsers = await firestoreTimestamps(snowflakeUsers.rows)
      snowflakeUsers = convertKeys(snowflakeUsers, 'toCamelCase')
      snowflakeUsers = snowflakeUsers.map(m => ({
        ...m,
        id: m.loginName,
        enabled: m.disabled !== 'true',
        displayName: m.displayName || m.loginName,
        hasPassword: m.hasPassword === 'true',
        lastLogin: m.lastSuccessLogin,
      }))
      commit('setSnowflakeUsers', snowflakeUsers)
    },

    async refreshSnowflakeWarehouses({ commit }) {
      let snowflakeWarehouses = await snowflakeQuery('show warehouses', 'account')
      snowflakeWarehouses = await firestoreTimestamps(snowflakeWarehouses.rows)
      snowflakeWarehouses = convertKeys(snowflakeWarehouses, 'toCamelCase')

      // Join on Warehouse Grants
      const promises = []
      snowflakeWarehouses
        .map(m => m.name)
        .forEach(warehouse => {
          promises.push(snowflakeQuery(`show grants on warehouse "${warehouse}"`, 'account'))
        })
      const resolveWarehouseGrants = await Promise.all(promises)
      resolveWarehouseGrants.forEach(warehouse => {
        const warehouseIndex = snowflakeWarehouses.findIndex(f => f.name === warehouse.rows[0].name)
        const roleGrants = []
        warehouse.rows.forEach(grant => {
          roleGrants.push(grant.grantee_name)
        })
        snowflakeWarehouses[warehouseIndex].roles = roleGrants
      })

      // const warehouseGrants = await snowflakeQuery(grantQuery, 'account')
      commit('setSnowflakeWarehouses', snowflakeWarehouses)
    },

    // SYNCS ////////////////
    // ///// ///////////////
    // ///// //////////////

    // SYNC Snowflake Bookmarks
    // /////////////////////
    syncSnowflakeBookmarks({ commit, rootState }) {
      const account = rootState.accountProfile
      onSnapshot(doc(db, `bookmarks/accounts/${account.id}`, 'snowflakeBookmarks'), async response => {
        commit('setSnowflakeBookmarks', response.data())
        commit('syncList', 'snowflakeData/syncSnowflakeBookmarks', { root: true })
      })
    },

    // Snowflake Roles
    // ///////////////
    syncSnowflakeRoles({ commit, rootState }) {
      const account = rootState.accountProfile
      onSnapshot(doc(db, `snowflakeRoles/accounts/${account.id}`, 'roles'), async response => {
        let snowflakeRoles = await firestoreTimestamps(response.data().roles)
        console.log('SNOWFLAKE ROLES', snowflakeRoles)
        if (rootState.showSampleData) snowflakeRoles = sampleRoles(snowflakeRoles)
        commit('setSnowflakeRoles', snowflakeRoles)
        commit('syncList', 'snowflakeData/syncSnowflakeRoles', { root: true })
      })
    },

    syncSnowflakeUserRoles({ commit, rootState }) {
      const account = rootState.accountProfile
      onSnapshot(doc(db, `snowflakeRoles/accounts/${account.id}`, 'userRoles'), response => {
        let snowflakeUserRoles = response.data().userRoles
        if (rootState.showSampleData) snowflakeUserRoles = sampleUserRoles(snowflakeUserRoles)
        commit('setSnowflakeUserRoles', snowflakeUserRoles)
        commit('syncList', 'snowflakeData/syncSnowflakeUserRoles', { root: true })
      })
    },

    // Snowflake Tasks Data
    // /////////////////////
    async syncSnowflakeTasks({ commit, rootState, state }) {
      const account = rootState.accountProfile
      const bookmark = state.snowflakeBookmarks?.snowflakeTasks
      if (!bookmark) return
      const q = query(collection(db, `snowflakeTasks/accounts/${account.id}`), where('month', '==', 'current'))
      onSnapshot(q, querySnapshot => {
        querySnapshot.forEach(response => {
          commit('setSnowflakeTasks', response.data().rows)
          commit('syncList', 'snowflakeData/syncSnowflakeTasks', { root: true })
        })
      })
    },

    // Snowflake Consumption Data
    // /////////////////////

    syncSnowflakeConsumptionDaily({ commit, rootState }) {
      let consumptionDays = []
      const account = rootState.accountProfile
      const timeZone = JSON.parse(localStorage.getItem('timeZone'))
      const { zone } = timeZone

      // Get X Months Ago - Default 1 But needs at least 7 days of data
      const startDate = moment()
        .subtract(7, 'days')
        .format('YYYYMM')
      const q = query(collection(db, `snowflakeConsumption/accounts/${account.id}`), where('month', '>=', startDate), where('timeZone', '==', zone))
      onSnapshot(q, querySnapshot => {
        querySnapshot.forEach(response => {
          const responseData = Object.values(response.data().rows)
          consumptionDays = [...consumptionDays, ...responseData]
        })
        commit('setSnowflakeConsumptionDaily', consumptionDays)
        consumptionDays = [] // Clears Array Cache
        commit('syncList', 'snowflakeData/syncSnowflakeConsumptionDaily', { root: true })
      })
    },

    syncSnowflakeConsumptionMonthly({ commit, rootState }) {
      let consumptionMonths = []
      const account = rootState.accountProfile
      const timeZone = JSON.parse(localStorage.getItem('timeZone'))
      const { zone } = timeZone

      // Get X Years Ago - Default 1
      const startDate = moment()
        .subtract(1, 'years')
        .format('YYYY')
      const q = query(collection(db, `snowflakeConsumption/accounts/${account.id}`), where('year', '>=', startDate), where('timeZone', '==', zone))
      onSnapshot(q, querySnapshot => {
        querySnapshot.forEach(response => {
          const responseData = Object.values(response.data().rows)
          consumptionMonths = [...consumptionMonths, ...responseData]
        })
        commit('setSnowflakeConsumptionMonthly', consumptionMonths)
        consumptionMonths = [] // Clears Array Cache
        commit('syncList', 'snowflakeData/setSnowflakeConsumptionMonthly', { root: true })
      })
    },

    syncSnowflakeStorage({ commit, rootState }) {
      let snowflakeStorage = []
      const account = rootState.accountProfile

      // Get X Months Ago - Default 1 But needs at least 7 days of data
      const startDate = moment()
        .utc()
        .format('YYYYMMDD')
      const q = query(collection(db, `snowflakeStorage/accounts/${account.id}`), where('day', '==', startDate))
      onSnapshot(q, querySnapshot => {
        querySnapshot.forEach(response => {
          const data = response.data().object
          const databases = Object.keys(data)
          databases.forEach(database => {
            const schemas = Object.keys(data[database])
            schemas.forEach(schema => {
              const tables = Object.keys(data[database][schema])
              tables.forEach(table => {
                snowflakeStorage.push({ database, schema, table, ...data[database][schema][table] })
              })
            })
          })
        })
        commit('setSnowflakeStorage', snowflakeStorage)
        snowflakeStorage = [] // Clears Array Cache
        commit('syncList', 'snowflakeData/syncSnowflakeStorage', { root: true })
      })
    },

    // Snowflake Executions Daily
    // //////////////////////////

    syncSnowflakeExecutionsDaily({ commit, state, rootState }) {
      let executionsDays = []
      const account = rootState.accountProfile
      const daysBack = state.snowflakeDataDaysBack
      const timeZone = JSON.parse(localStorage.getItem('timeZone'))
      const { zone } = timeZone

      // Get X Months Ago - Default 1 But needs at least 7 days of data
      const startDate = moment()
        .subtract(daysBack, 'days')
        .format('YYYYMM')
      const q = query(collection(db, `snowflakeExecutions/accounts/${account.id}`), where('month', '>=', startDate), where('timeZone', '==', zone))
      onSnapshot(q, querySnapshot => {
        querySnapshot.forEach(response => {
          const responseData = Object.values(response.data().rows)
          executionsDays = [...executionsDays, ...responseData]
        })
        commit('setSnowflakeExecutionsDaily', executionsDays)
        executionsDays = [] // Clears Array Cache
        commit('syncList', 'snowflakeData/syncSnowflakeExecutionsDaily', { root: true })
      })
    },

    // Snowflake Executions Monthly
    // //////////////////////////

    syncSnowflakeExecutionsMonthly({ commit, rootState }) {
      let executionsMonths = []
      const account = rootState.accountProfile
      const timeZone = JSON.parse(localStorage.getItem('timeZone'))
      const { zone } = timeZone

      // Get X Months Ago - Default 1 But needs at least 7 days of data
      const startDate = moment()
        .subtract(1, 'year')
        .format('YYYY')
      const q = query(collection(db, `snowflakeExecutions/accounts/${account.id}`), where('year', '>=', startDate), where('timeZone', '==', zone))
      onSnapshot(q, querySnapshot => {
        querySnapshot.forEach(response => {
          const responseData = Object.values(response.data().rows)
          executionsMonths = [...executionsMonths, ...responseData]
        })
        commit('setSnowflakeExecutionsMonthly', executionsMonths)
        executionsMonths = [] // Clears Array Cache
        commit('syncList', 'snowflakeData/syncSnowflakeExecutionsMonthly', { root: true })
      })
    },

    // Snowflake Table Freshness Data
    // //////////////////////////////

    syncSnowflakeTableFreshness({ commit, rootState }) {
      let tableFreshnessData = []
      const account = rootState.accountProfile

      // const timeZone = JSON.parse(localStorage.getItem('timeZone'))

      // const { zone } = timeZone
      // const dateRangeFilter = Object.assign(rootState.dateRangeFilter.filter(f => f.active)[0])
      const dateType = 'month' // dateRangeFilter.objectType === 'Daily' ? 'month' : 'year'
      const dateBlock = '202205' // dateRangeFilter.objectType === 'Daily' ? moment().format('YYYYMM') : moment().format('YYYY')
      const q = query(collection(db, `snowflakeTableFreshness/accounts/${account.id}`), where(dateType, '==', dateBlock))
      onSnapshot(q, querySnapshot => {
        querySnapshot.forEach(response => {
          const responseData = Object.values(response.data().rows)

          // Payload Includes Additional Days of Data to Conform to Local Timezone
          // Remove Data That Doesn't Conform To Local Timezone
          // :todo
          tableFreshnessData = responseData
        })
        commit('setSnowflakeTableFreshness', tableFreshnessData)
        tableFreshnessData = [] // Clears Array Cache
        commit('syncList', 'snowflakeData/syncSnowflakeTableFreshness', { root: true })
      })
    },

    // Snowflake Contract Details
    // //////////////////////////
    snowflakeContractDetails({ commit, rootState }) {
      let creditCost = 3
      let contractDays = 365
      let perDayCreditAllowance = null
      const account = rootState.accountProfile
      const { contractAmount, contractAllowedCredits } = account?.snowflakeSettings
      let { contractStart, contractEnd } = account?.snowflakeSettings

      if (contractStart && contractStart.seconds) {
        contractStart = new Date(contractStart.seconds * 1000)
        if (!contractEnd) {
          contractEnd = moment(contractStart)
            .add(1, 'year')
            .toDate()
        } else {
          contractEnd = new Date(contractEnd.seconds * 1000)
        }

        // contractDays = moment.duration(contractStart.diff(contractEnd)).asDays()
        contractDays = moment(contractStart).diff(contractEnd, 'days') * -1
      } // Calculate Each Credit Cost
      if (contractAmount && contractAllowedCredits && Number(contractAmount) > 0 && Number(contractAllowedCredits) > 0) {
        creditCost = contractAmount / contractAllowedCredits
        if (creditCost < 0.5) creditCost = 3
        perDayCreditAllowance = Math.round(contractAllowedCredits / contractDays)
        if (typeof perDayCreditAllowance !== 'number' || perDayCreditAllowance === -Infinity) {
          perDayCreditAllowance = null
        }
      }

      const data = {
        creditCost,
        contractDays,
        perDayCreditAllowance,
        contractAmount,
        contractAllowedCredits,
        contractStart,
        contractEnd,
      }
      commit('setSnowflakeContractDetails', data)
    },
  },
  getters: {
    // User Consumption Aggregation by TimePeriod
    // Get Execution Data And Adds Users To Arrays and Adds Credit and Cost
    // Warehouse Arrays Reference = [0 - executions, 1 - duration, 2 - % of warehouseTotal, 3 - credits, 4 - cost]
    snowflakeUserConsumptionDaily(state) {
      const type = 'Daily'
      const timestampName = 'day'

      const userExecutions = state[`snowflakeExecutions${type}`]
      const contractData = state.snowflakeContractDetails
      const globalConsumption = state[`snowflakeConsumption${type}`]

      return userConsumption({ timestampName, userExecutions, contractData, globalConsumption })
    },
    snowflakeUserConsumptionMonthly(state) {
      const type = 'Monthly'
      const timestampName = 'month'

      const userExecutions = state[`snowflakeExecutions${type}`]
      const contractData = state.snowflakeContractDetails
      const globalConsumption = state[`snowflakeConsumption${type}`]

      return userConsumption({ timestampName, userExecutions, contractData, globalConsumption })
    },
    snowflakeWarehouseConsumptionByFilter(state, none, rootState) {
      // Get Current Date Range From Date Range Filter
      const { objectType, objectName, startDate, endDate } = rootState.dateRangeFilter.filter(f => f.active)[0]
      const dateFormat = objectType === 'Daily' ? 'YYYYMMDD' : 'YYYYMM'

      const getWarehouseConsumption = state[`snowflakeConsumption${objectType}`]
      const getContractData = state.snowflakeContractDetails

      let warehouseData = getWarehouseConsumption.filter(f => f[objectName] >= moment(startDate).format(dateFormat) && f[objectName] <= moment(endDate).format(dateFormat))

      const groupByWarehouseConsumption = []
      warehouseData.forEach(row => {
        Object.keys(row.warehouses).forEach(warehouse => {
          groupByWarehouseConsumption.push({ warehouse, credits: row.warehouses[warehouse] })
        })
      })

      // Group Warehouses and Sum Data
      warehouseData = _(groupByWarehouseConsumption)
        .groupBy('warehouse')
        .map((m, warehouse) => ({
          warehouse,
          credits: _.sumBy(m, 'credits'),
          cost: Math.round(_.sumBy(m, 'credits') * getContractData.creditCost),
        }))
        .value()

      return _.orderBy(warehouseData, ['credits'], ['desc'])
    },
    snowflakeUserConsumptionByFilter(state, getters, rootState) {
      // Get Current Date Range From Date Range Filter
      const { objectType, objectName, startDate, endDate } = rootState.dateRangeFilter.filter(f => f.active)[0]

      // Not that depending on the Range Requirements Different Objects (Monthly or Daily) Could be pulled from 'objectType'
      const getExecutionData = state[`snowflakeExecutions${objectType}`]
      const globalConsumption = state[`snowflakeConsumption${objectType}`]
      const contractData = state.snowflakeContractDetails

      // const getContractData = state.snowflakeData.snowflakeContractDetails
      const dateFormat = objectType === 'Daily' ? 'YYYYMMDD' : 'YYYYMM'

      const userExecutions = getExecutionData.filter(f => f[objectName] >= moment(startDate).format(dateFormat) && f[objectName] <= moment(endDate).format(dateFormat))
      const executionData = userConsumption({
        timestampName: objectName,
        userExecutions,
        contractData,
        globalConsumption,
      })

      const groupByUser = sumUserConsumptionData(executionData)

      // Group into High, Medium, Low Consumption
      const consumptionDist = (_.meanBy(groupByUser, 'duration') * 2) / 3
      const executionDist = (_.meanBy(groupByUser, 'executions') * 2) / 3

      // Assign User into Distribution (High, Medium, Low) Buckets
      const executionDistribution = groupByUser.map(m => ({
        ...m,
        consumptionBucket: distributionHighMediumLow(m.duration, consumptionDist),
        executionBucket: distributionHighMediumLow(m.executions, executionDist),
        executionTotalsAbbr: formatNumbers(m.executions),
      }))

      return executionDistribution
    },
    snowflakeUsersAndRoles(state, getters, rootState) {
      // Add Snowflake User Roles / App IDs / To Data Set

      const getSnowflakeUsers = state.snowflakeUsers
      const getSnowflakeUserRoles = state.snowflakeUserRoles
      const { appUsers } = rootState.users
      const users = []

      // Add Roles / App Status / Consumption / Warehouses
      getSnowflakeUsers.forEach(user => {
        // Roles
        const roles = getSnowflakeUserRoles.filter(f => f.granteeName === user.id).map(m => m.role)

        // App Status
        const appUser = appUsers.filter(f => f.snowflakeCredentials.username === user.id)[0]
        const userObject = { ...user, roles }
        if (appUser) userObject.appUserId = appUser.id
        users.push(userObject)
      })

      return users
    },
  },
  modules: {},
}
